/* eslint-disable no-unused-vars */
import React from 'react'
import { useRef, useState } from 'react'
import '../styles/HomeScreen.css'
import occhiogif from '../assets/icona sito web mod.gif'
import arrow from '../assets/output-onlinegiftools.gif'
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';

const HomeScreen = () => {
  const [a, setA] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [modal] = useState(false);
    if(modal) {
      document.body.classList.add('active-modal')
    } else {
      document.body.classList.remove('active-modal')
    }
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_foa7t03', 'template_1v45r88', form.current, 'TS9WbpKrWNq2gt0U_')
    .then((result) => {
      console.log(result.text);
      toast.success(' Richiesta Effettuata!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }, (error) => {
      console.log(error.text);
      toast.error('🦄 Wow so easy!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
  });
  e.target.reset();
  };
  return (
    <div id='hms' className=''>
      <div id='congif'>
        <div id='itemgif'>
          <img id='log' src={occhiogif} alt='occhiog'/>
        </div>
      </div>
        {/* <div id='hmsc' className=''>
            <div id="tab" className="">
              <h3 className="chomes justify-content-center p-3 position-relative">
              <div>
                <h3 id="demo" className=" justify-content-center d-grid position-relative ">RICHIEDI UN AUDIT GRATUITO
                </h3>
                <br></br>
              </div>
              
                <button id='btnhs'>cliccando qui verrai portato in una pagina dove compilare il modulo</button>
                </h3>
              </div>
        </div> */}
        <img id='ar' src={arrow} alt='arr'/>
    </div>
  )
}

export default HomeScreen