import React from 'react'
import '../styles/Aziende.css'
import a from '../assets/kik.jpg'

const Aziende = () => {
  return (
    <div id='spo'>
      <img id='re' src={a} alt='aziende'/>
    </div>
  )
}

export default Aziende