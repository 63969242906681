/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { CSSTransition } from 'react-transition-group';
import '../styles/Cards.css'
import asp from '../assets/sicurezzaeventi/ASSISTENZA-SPETTACOLO.jpg'
import pde from '../assets/sicurezzaeventi/PIANO-DELLE-EMERGENZE.jpg'
import pein from '../assets/sicurezzaeventi/PREVENZIONE-INCENDI.jpg'
import psoc from '../assets/sicurezzaeventi/PRIMO-SOCCORSO.jpg'
import impa from '../assets/sicurezzaeventi/IMPATTO-ACUSTICO.jpg'
import merc from '../assets/sicurezzaeventi/AREA-MERCATALE.jpg'
import { toast } from 'react-toastify';


const sicurezzaeventi = () => {
  const form = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [modal] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_foa7t03', 'template_1v45r88', form.current, 'TS9WbpKrWNq2gt0U_')
    .then((result) => {
      console.log(result.text);
      toast.success(' Richiesta Effettuata!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }, (error) => {
      console.log(error.text);
      toast.error('🦄 Wow so easy!', {
        position: "top-right",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
  });
  e.target.reset();
  };

  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }
  return (
    <>
    <div >
      <section className='section bg-white h-100'>
        <div className='container1'><br></br><br></br><br></br><br></br>
          <h1 id='tit'>Sicurezza Eventi</h1> 
          <div>
            <div id='descrserv'>
              <p id='po'>Attraverso professionisti del settore, progettiamo e controlliamo
                          gli aspetti di “safety” e di “security” delle pubbliche manifestazioni.</p>
            </div>
          </div>
          <div id='descrserv2'>
            <button id='bssl1' onClick={() => setIsOpen(!isOpen)}>Richiedi informazioni</button>
            <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="popup"
        unmountOnExit
        >
        <div className="popup-content ">
          <p style={{ color: "white", fontSize:'1.5rem', fontWeight:'bold'}}>Abbiamo bisogno di alcune Informazioni..</p><br></br>
          <form ref={form} onSubmit={sendEmail}>
              <div >
                <div style={{ width: "40%" }}>
                  <label htmlFor="nominativo" style={{ color: "white" }}>Il tuo Nominativo:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="nominativo" name="nominativo" required/>
                </div>
                <br />
                <br />
                <div style={{ width: "90%" }}>
                  <label htmlFor="nominativo" style={{ color: "white" }}>Il Nome della tua Impresa (facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="nImpresa" name="nImpresa" />
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "40%" }}>
                  <label htmlFor="email" style={{ color: "white" }}>La tua Email:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="email" id="email" name="user_email" required />
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="provincia" style={{ color: "white" }}>In quale Provincia si trova la tua Impresa:</label>
                </div>
                <div style={{ width: "100%" }}>
                <select id="province" style={{ width: "100%" }} name='user_province' required>
                  <option value="”Agrigento”">Agrigento</option>
                  <option value="”Alessandria”">Alessandria</option>
                  <option value="”Ancona”">Ancona</option>
                  <option value="”Aosta”">Aosta</option>
                  <option value="”Arezzo”">Arezzo</option>
                  <option value="”Ascoli piceno”">Ascoli piceno</option>
                  <option value="”Asti”">Asti</option>
                  <option value="”Avellino”">Avellino</option>
                  <option value="”Bari”">Bari</option>
                  <option value="”Barletta-Andria-Trani”">Barletta-Andria-Trani</option>
                  <option value="”Belluno”">Belluno</option>
                  <option value="”Benevento”">Benevento</option>
                  <option value="”Bergamo”">Bergamo</option>
                  <option value="”Biella”">Biella</option>
                  <option value="”Bologna”">Bologna</option>
                  <option value="”Bolzano”">Bolzano</option>
                  <option value="”Brescia”">Brescia</option>
                  <option value="”Brindisi”">Brindisi</option>
                  <option value="”Cagliari”">Cagliari</option>
                  <option value="”Caltanissetta”">Caltanissetta</option>
                  <option value="”Campobasso”">Campobasso</option>
                  <option value="”Carbonia-Iglesias”">Carbonia-Iglesias</option>
                  <option value="”Caserta”">Caserta</option>
                  <option value="”Catania”">Catania</option>
                  <option value="”Catanzaro”">Catanzaro</option>
                  <option value="”Chieti”">Chieti</option>
                  <option value="”Como”">Como</option>
                  <option value="”Cosenza”">Cosenza</option>
                  <option value="”Cremona”">Cremona</option>
                  <option value="”Crotone”">Crotone</option>
                  <option value="”Cuneo”">Cuneo</option>
                  <option value="”Enna”">Enna</option>
                  <option value="”Fermo”">Fermo</option>
                  <option value="”Ferrara”">Ferrara</option>
                  <option value="”Firenze”">Firenze</option>
                  <option value="”Foggia”">Foggia</option>
                  <option value="”Forli’-Cesena”">Forli’-Cesena</option>
                  <option value="”Frosinone”">Frosinone</option>
                  <option value="”Genova”">Genova</option>
                  <option value="”Gorizia”">Gorizia</option>
                  <option value="”Grosseto”">Grosseto</option>
                  <option value="”Imperia”">Imperia</option>
                  <option value="”Isernia”">Isernia</option>
                  <option value="”la Spezia”">la Spezia</option>
                  <option value="”l’Aquila”">l’Aquila</option>
                  <option value="”Latina”">Latina</option>
                  <option value="”Lecce”">Lecce</option>
                  <option value="”Lecco”">Lecco</option>
                  <option value="”Livorno”">Livorno</option>
                  <option value="”Lodi”">Lodi</option>
                  <option value="”Lucca”">Lucca</option>
                  <option value="”Macerata”">Macerata</option>
                  <option value="”Mantova”">Mantova</option>
                  <option value="”Massa-Carrara”">Massa-Carrara</option>
                  <option value="”Matera”">Matera</option>
                  <option value="”Medio Campidano”"> Medio Campidano</option>
                  <option value="”Messina”">Messina</option>
                  <option value="”Milano”">Milano</option>
                  <option value="”Modena”">Modena</option>
                  <option value="”Monza e della Brianza”">Monza e della Brianza</option>
                  <option value="”Napoli”">Napoli</option>
                  <option value="”Novara”">Novara</option>
                  <option value="”Nuoro”">Nuoro</option>
                  <option value="”Ogliastra”">Ogliastra</option>
                  <option value="”Olbia-Tempio”">Olbia-Tempio</option>
                  <option value="”Oristano”">Oristano</option>
                  <option value="”Padova”">Padova</option>
                  <option value="”Palermo”">Palermo</option>
                  <option value="”Parma”">Parma</option>
                  <option value="”Pavia”">Pavia</option>
                  <option value="”Perugia”">Perugia</option>
                  <option value="”Pesaro e Urbino”">Pesaro e Urbino</option>
                  <option value="”Pescara”">Pescara</option>
                  <option value="”Piacenza”">Piacenza</option>
                  <option value="”Pisa”">Pisa</option>
                  <option value="”Pistoia”">Pistoia</option>
                  <option value="”Pordenone”">Pordenone</option>
                  <option value="”Potenza”">Potenza</option>
                  <option value="”Prato”">Prato</option>
                  <option value="”Ragusa”">Ragusa</option>
                  <option value="”Ravenna”">Ravenna</option>
                  <option value="”Reggio di Calabria”">Reggio di Calabria</option>
                  <option value="”Reggio nell’Emilia”">Reggio nell’Emilia</option>
                  <option value="”Rieti”">Rieti</option>
                  <option value="”Rimini”">Rimini</option>
                  <option value="”Roma”">Roma</option>
                  <option value="”Rovigo”">Rovigo</option>
                  <option value="”Salerno”">Salerno</option>
                  <option value="”Salerno”">Salerno</option>
                  <option value="”Savona”">Savona</option>
                  <option value="”Siena”">Siena</option>
                  <option value="”Siracusa”">Siracusa</option>
                  <option value="”Sondrio”">Sondrio</option>
                  <option value="”Taranto”">Taranto</option>
                  <option value="”Teramo”">Teramo</option>
                  <option value="”Terni”">Terni</option>
                  <option value="”Torino”">Torino</option>
                  <option value="”Trapani”">Trapani</option>
                  <option value="”Trento”">Trento</option>
                  <option value="”Treviso”">Treviso</option>
                  <option value="”Trieste”">Trieste</option>
                  <option value="”Udine”">Udine</option>
                  <option value="”Varese”">Varese</option>
                  <option value="”Venezia”">Venezia</option>
                  <option value="”Verbano-Cusio-Ossola”">Verbano-Cusio-Ossola</option>
                  <option value="”Vercelli”">Vercelli</option>
                  <option value="”Verona”">Verona</option>
                  <option value="”Vibo Valentia”">Vibo Valentia</option>
                  <option value="”Vicenza”">Vicenza</option>
                  <option value="”Viterbo”">Viterbo</option>
              </select>
                  {/* <input type="text" id="provincia" name="provincia" /> */}
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="provincia" style={{ color: "white" }}>Sono interessato a:</label>
                </div>
                <div style={{ width: "100%" }}>
                <select id="province" style={{ width: "100%" }} name='user_interes' required>
                  <option value="”voglio essere guidato”">VOGLIO ESSERE GUIDATO</option>
                  <option value="”INFORMAZIONE DEI LAVORATORI PER LA SICUREZZA”">informazione dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE GENERALE DEI LAVORATORI PER LA SICUREZZA”">formazione generale dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE SPECIFICA ED AGGIORNAMENTO DEI LAVORATORI PER LA SICUREZZA”">formazione specifica ed aggiornamento dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE, AGGIORNAMENTO ED ADDESTRAMENTO PER RESPONSABILI DELL'ATTUAZIONE DELLE MISURE DI EMERGENZA ANTINCENDIO”">formazione, aggiornamento ed addestramento per responsabili dell'attuazione delle misure di emergenza antincendio</option>
                  <option value="”FORMAZIONE, AGGIORNAMENTO ED ADDESTRAMENTO PER RESPONSABILI DELL'ATTUAZIONE DELLE MISURE DI PRIMO SOCCORSO”">formazione, aggiornamento ed addestramento per responsabili dell'attuazione delle misure di primo soccorso</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO PER RAPPRESENTANTI DEI LAVORATORI PER LA SICUREZZA”">Formazione ed aggiornamento per rappresentanti dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE PER PREPOSTI ALLA SICUREZZA”">formazione per preposti alla sicurezza</option>
                  <option value="”FORMAZIONE PER DIRIGENTI PER LA SICUREZZA”">formazione per dirigenti per la sicurezza</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARROPONTI”">formazione ed addestramento per addetti all'utilizzo di carroponti</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI E PREPOSTI AL MONTAGGIO,SMONTAGGIO E TRASFORMAZIONE DEI PONTEGGI”">formazione ed addestramento per addetti e preposti al montaggio, smontaggio e trasformazione dei ponteggi</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI AD OPERARE SU PARTI ELETTRICHE”">formazione ed addestramento per addetti ad operare su parti elettriche</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI OTOPROTETTORI”">formazione ed addestramento per l'utilizzo di otoprotettori</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI SISTEMI ANTICADUTA”">formazione ed addestramento per l'utilizzo di sistemi anticaduta</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI MASCHERE FILTRANTI”">formazione ed addestramento per l'utilizzo di maschere filtranti</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI SISTEMI DI ACCESSO E POSIZIONAMENTO FUNI”">formazione ed addestramento per l'utilizzo di sistemi di accesso e posizionamento funi</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI PIATTAFORME ELEVABILI (PLE)”">formazione ed addestramento per addetti all'utilizzo di piattaforme elevabili (PLE)</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU SU AUTOCARRO”">formazione ed addestramento per addetti all'utilizzo di gru su autocarro</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU A TORRE”">formazione ed addestramento per addetti all'utilizzo di gru a torre</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARRELLI ELEVATORI”">formazione ed addestramento per addetti all'utilizzo di carrelli elevatori</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU MOBILI AUTOCARRATE E SEMOVENTI SU RUOTE”">formazione ed addestramento per addetti all'utilizzo di gru mobili autocarrate e semoventi su ruote</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU MOBILI CON FALCONE TELESCOPICO E BRANDEGGIABILE”">formazione ed addestramento per addetti all'utilizzo di gru mobili con falcone telescopico e brandeggiabile</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TRATTORI AGRICOLI O FORESTALI SU RUOTE”">formazione ed addestramento per addetti all'utilizzo di trattori agricoli o forestali su ruote</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TRATTORI AGRICOLI O FORESTALI SU CINGOLI”">formazione ed addestramento per addetti all'utilizzo di trattori agricoli o forestali su cingoli</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI IDRAULICI”">formazione ed addestramento per addetti all'utilizzo di escavatori idraulici</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI A FUNE”">formazione ed addestramento per addetti all'utilizzo di escavatori a fune</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARICATORI FRONTALI”">formazione ed addestramento per addetti all'utilizzo di caricatori frontali</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TERNE”">formazione ed addestramento per addetti all'utilizzo di terne</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI AUTORIBALTABILI A CINGOLI”">formazione ed addestramento per addetti all'utilizzo di autoribaltabili a cingoli</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI-CARICATORI-TERNE”">formazione ed addestramento per addetti all'utilizzo di escavatori-caricatori-terne</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI AUTOPOMPE PER CALCESTRUZZO”">formazione ed addestramento per addetti all'utilizzo di autopompe per calcestruzzo</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER RISCHI SPECIFICI”">formazione ed addestramento per rischi specifici</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER LAVORI IN AMBIENTI CONFINATI O CON SOSPETTO DI INQUINAMENTO”">formazione ed addestramento per lavori in ambienti confinati o con sospetto di inquinamento</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER LA MANIPOLAZIONE DEGLI ALIMENTI”">formazione ed addestramento per la manipolazione degli alimenti</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO ABILITANTE ALLA FUNZIONE DI RESPONSABILI DEL SERVIZIO DI PREVENZIONE E PROTEZIONE”">formazione ed aggiornamento abilitante alla funzione di responsabili del servizio di prevenzione e protezione</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO ABILITANTE ALLA FUNZIONE DI COORDINATORE DELLA SICUREZZA NEI CANTIERI”">formazione ed aggiornamento abilitante alla funzione di coordinatore della sicurezza nei cantieri</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO PER FORMATORI DELLA SICUREZZA”">formazione ed aggiornamento per formatori della sicurezza</option>
              </select>
                  {/* <input type="text" id="provincia" name="provincia" /> */}
                </div>
                {/* <Multiselect id='province' name='user_interes' style={{ width: "100%" }} className='bg-white'
                  isObject={false}
                  onRemove={function noRefCheck(){}}
                  onSearch={function noRefCheck(){}}
                  onSelect={function noRefCheck(){}}
                  options={inf}
                /> */}
              </div>
              <br/>
              <br/>
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="settore" style={{ color: "white" }}>Aggiungi richieste (facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <textarea style={{ width: "100%" }} name='user_mot'></textarea>
                </div>
              </div>
              <br/>
              <br/>
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="settore" style={{ color: "white" }}>In che Settore opera la tua Impresa (facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="settore" name="settore" />
                </div>
              </div>
              <br />
              <div style={{ fontWeight:'bold', color:'white', fontSize:'13px' }}>
              Tratteremo i tuoi dati nel pieno rispetto dei principi dettati dal Regolamento Europeo in materia di protezione dei dati personali (GDPR) .
               <br/>Cliccando su "Invia richiesta" accetti la nostra Informativa<a href='#'style={{ fontWeight:'bold', color:'white', fontSize:'13px' }}>(consultabile qui).</a>
              </div>
              <br />
              <input type="submit" value="Invia richiesta" id='bssl2'/>
            </form>
        </div> 
      </CSSTransition>
          </div>
          <div id='descrserv3'>
            ti risponderemo in 24 ore
          </div>
          <div className='cards'>
            {
              <div className='card'>
                <img src={asp} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>ASSISTENZA NEI RAPPORTI CON
                                GLI ENTI COMPETENTI E CON LA
                                COMMISSIONE PUBBLICO SPETTACOLO</h3><br></br><br></br>
                {/* {
                  a?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setA(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setA(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={pde} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>ELABORAZIONE DEL
                                PIANO DELLE EMERGENZE</h3><br></br><br></br>
                {/* {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={pein} id='cardimg'>{}</img><br></br><br></br>
                <h3 id='tecard'>ELABORAZIONE DELLA RELAZIONE DI
                                PREVENZIONE INCENDI PER
                                L'OTTENIMENTO DEL PARERE DI
                                CONFORMITÀ (D.P.R. DEL 28/05/2001 N.311)</h3><br></br><br></br>
                {/* {
                  c?<><p id='pcol'>
                    </p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setC(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setC(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={psoc} id='cardimg'>{}</img><br></br><br></br>
                <h3 id='tecard'>ELABORAZIONE DELLA RELAZIONE
                                RIPORTANTE L'ORGANIZZAZIONE DEI
                                SOCCORSI SANITARI E PRESENTAZIONE
                                AL PRIMO SOCCORSO</h3><br></br><br></br>
                {/* {
                  d?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setD(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setD(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={impa} id='cardimg'>{}</img><br></br><br></br>
                <h3 id='tecard'>REDAZIONE DEL DOCUMENTO DI
                                PREVISIONE DI IMPATTO ACUSTICO</h3><br></br><br></br>
                {/* {
                  e?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={merc} id='cardimg'>{}</img><br></br><br></br>
                <h3 id='tecard'>REDAZIONE DEL PIANO DI
                                GESTIONE DELLE AREE MERCATALI</h3><br></br><br></br>
                {/* {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            } 
          </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default sicurezzaeventi