import React from 'react'
import '../../styles/Cards.css'
import striscioneElett from '../../assets/partielettriche/Banner FORMAZIONE PER ADDETTI AI LAVORI ELETTRICI.jpg'
import attestato from '../../assets/partielettriche/attestato.png'
import carrello from '../../assets/partielettriche/carrello.png'
import documento from '../../assets/partielettriche/documento.png'
import lezione from '../../assets/partielettriche/lezione.png'
import obiettivi from '../../assets/partielettriche/obiettivi.png'
import omino from '../../assets/partielettriche/omino.png'
import persone from '../../assets/partielettriche/persone.png'
import posizione from '../../assets/partielettriche/posizione.png'
import scarpa from '../../assets/partielettriche/scarpa.png'
import spuntato from '../../assets/partielettriche/spuntato.png'

const partiElettriche = () => {
    return (
        <>
        <div >
          <section className='section bg-white'>
            <div ><br></br><br></br>
                <h1 id='tit2'> Addetti ai lavori elettrici</h1>
                <div style={{display:'flex',width: '100%', height: '0%',alignItems:'center', justifyContent:'center', marginBottom:'3%'}}>
                    <img style={{color: '#035394', textAlign: 'center', width:'80%'}} src={striscioneElett}></img>
                </div>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <h4 style={{color: '#035394', textAlign: 'center', width:'60%'}}>FORMAZIONE PER ADDETTI AI LAVORI ELETTRICI (PES-PAV-PEI) <br/> NORMA CEI 11 - 27 V EDIZIONE 2021</h4>
                </div>
                <br></br>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{width: '80%', height: '80%', textAlign: 'center'}}>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        La Norma <h style={{fontSize:'100%', color:'#ec6521'}}>CEI 11-27</h>, nella sua ultima edizione in vigore da ottobre 2021, prevede che le lavorazioni su impianti elettrici messi fuori tensione durante l’intervento,
                        le lavorazioni svolte nelle immediate vicinanze di parti in tensione non protette e le lavorazioni sotto tensione su impianti fino a 1000 V in c.a. e 1500 V in c.c.
                        siano eseguite direttamente da un operatore qualificato come <h style={{fontSize:'100%', color:'#ec6521'}}>Persona Esperta</h> (PES) o <h style={{fontSize:'100%', color:'#ec6521'}}>Persona Avvertita</h> (PAV), oppure,
                        limitatamente alle prime due tipologie di lavorazioni, sotto la supervisione di PES o sotto la sorveglianza costante di PES o PAV.
                        La stessa previsione vale anche per le lavorazioni non elettriche, quali ad esempio lavori edili eseguiti in vicinanza di impianti
                        elettrici, di linee elettriche aeree o in vicinanza di cavi sotterranei non isolati o insufficientemente isolati (D. Lgs. 81/2008).
                        <br/>
                        L’attribuzione della condizione di PES o PAV per lavoratori dipendenti è di pertinenza del Datore di Lavoro. Per stabilire correttamente
                        quale profilo professionale (PES o PAV) attribuire a un operatore, è necessario riferirsi a tre requisiti tra loro complementari: 
                        <h style={{fontSize:'100%', color:'#ec6521'}}> istruzione; esperienza di lavoro maturata; caratteristiche personali</h>. Per gli operatori che non ne sono in possesso,
                        la Norma CEI 11 – 27 fornisce i requisiti minimi dei corsi di formazione che consentono di acquisire,
                        sviluppare e consolidare le conoscenze e le capacità necessarie ad ottenere la qualifica di Persona Esperta o Avvertita.
                        Il personale che lavora sotto tensione in BT, oltre ad essere qualificato PES o PAV, deve ricevere da parte del proprio Datore
                        di Lavoro <h style={{fontSize:'100%', color:'#ec6521'}}> l’idoneità all’esecuzione</h> di lavori specifici <h style={{fontSize:'100%', color:'#ec6521'}}>sotto tensione</h> su sistemi di Categoria 0 e 1.
                        </p>
                    </div>
                </div>
                <br></br>
            {/* <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={carrello} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>COSA SI INTENDE PER CARRELLO INDUSTRIALE SEMOVENTE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                    Per carrello industriale semovente si intende un qualsiasi veicolo dotato di ruote (eccetto quelli circolanti su rotaie)
                                    concepito per trasportare, trainare, spingere, sollevare, impilare o disporre su scaffalature qualsiasi tipo di carico
                                    ed azionato da un operatore a bordo su sedile.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={persone} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DESTINATARI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso è destinato agli addetti alle lavorazioni su impianti elettrici messi <h style={{fontSize:'100%', color:'#ec6521'}}>fuori tensione</h> durante l’intervento,
                                alle lavorazioni svolte nelle <h style={{fontSize:'100%', color:'#ec6521'}}>immediate vicinanze </h>di parti in tensione non protette e alle lavorazioni <h style={{fontSize:'100%', color:'#ec6521'}}>sotto tensione </h>
                                su impianti fino a 1000 V in c.a. e 1500 V in c.c., nonché alle lavorazioni non elettriche eseguite in vicinanza
                                di impianti elettrici, di linee elettriche aeree o in vicinanza di cavi sotterranei non isolati o insufficientemente isolati. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={obiettivi} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>OBIETTIVI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il percorso formativo è progettato per fornire ai partecipanti le conoscenze teoriche e le competenze necessarie per eseguire 
                                in modo sicuro le operazioni e le attività di lavoro sugli impianti elettrici e a quelle ad essi connesse,
                                nonché alle operazioni svolte in loro prossimità.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={documento} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>PROGRAMMA DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il percorso formativo, perfettamente aderente ai requisiti formativi individuati dalla Norma CEI 11-27 V edizione 2021, è strutturato in quattro livelli.
                                <li style={{textAlign:'left', fontSize:'100%'}}><h style={{fontSize:'100%', color:'#ec6521'}}>Livello 1A </h> – Conoscenze teoriche.</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}><h style={{fontSize:'100%', color:'#ec6521'}}>Livello 2A </h>– Conoscenze e capacità per l’operatività (aspetti teorici).</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}><h style={{fontSize:'100%', color:'#ec6521'}}>Livello 1B </h>– Conoscenze teoriche di base per lavori sotto tensione.</li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}><h style={{fontSize:'100%', color:'#ec6521'}}>Livello 2B </h>– Conoscenze pratiche sulle tecniche di lavoro sotto tensione (aspetti teorici).</li> 
                                Il percorso formativo deve essere completato da un <h style={{fontSize:'100%', color:'#ec6521'}}>addestramento operativo </h>(escluso dal presente programma) attraverso simulazioni,
                                affiancamento e/o altre iniziative utili alla corretta applicazione delle procedure operative pratiche previste dalla Norma CEI 11-27 V edizione 2021. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                {/* <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={posizione} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>SEDE DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso si terrà interamente presso l'Hotel D'Atri Montesilvano sito in <h style={{fontSize:'100%', color:'#ec6521'}}>Via Calabria, 14/1 – Montesilvano (PE)</h>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>           */}
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={lezione} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>METODOLOGIA DIDATTICA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                La metodologia didattica del corso sarà incentrata sull'<h style={{fontSize:'100%', color:'#ec6521'}}>approccio attivo</h>, che mette al centro dell'apprendimento l'allievo stesso.
                                Per garantire un efficace processo di insegnamento/apprendimento, bilanceremo lezioni frontali e interazioni attive.
                                Le lezioni frontali forniranno le basi teoriche necessarie, mentre si darà valore alle esperienze degli allievi in aula,
                                facilitando il confronto. Utilizzeremo materiali multimediali per arricchire l'esperienza di apprendimento. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={spuntato} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>VERIFICA DI APPRENDIMENTO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Al fine di verificare l’apprendimento, ogni partecipante sarà sottoposto a: 
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                test intermedi con domande a risposta multipla finalizzati a valutare i risultati raggiunti.  
                                </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                <h style={{fontSize:'100%', color:'#ec6521'}}>test finale </h>con domande a risposta multipla, che si riterrà superato con almeno il 70% di risposte corrette.
                                </li>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>   
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={attestato} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>ATTESTATO DI FREQUENZA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                L’esito positivo delle prove di verifica intermedia e finale, unitamente a una presenza pari al 100% del monte ore,
                                consente a ciascun partecipante di ricevere un attestato di frequenza con <h style={{fontSize:'100%', color:'#ec6521'}}>validità quinquennale</h>.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={attestato} style={{width:'7%', height:'14%', marginRight: '1%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>ATTRIBUZIONE QUALIFICA PES, PEV e PEI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il Datore di Lavoro, sulla scorta di quanto indicato sull’attestato di formazione e sulla base degli altri elementi
                                già in suo possesso (grado di esperienza di lavoro maturata e caratteristiche personali tali da far ritenere
                                l’operatore affidabile), attribuirà all’operatore, ai sensi della Norma CEI EN 50110-1 III Edizione del 2014
                                e della Norma CEI 11-27 V Edizione del 2021, la condizione di Persona Esperta (<h style={{fontSize:'100%', color:'#ec6521'}}>PES</h>*) o di Persona Avvertita (<h style={{fontSize:'100%', color:'#ec6521'}}>PAV</h>**)
                                nonché l’idoneità all’esecuzione di lavori sotto tensione su sistemi di Categoria 0 e 1 (<h style={{fontSize:'100%', color:'#ec6521'}}>PEI</h>***). 
                                </p>
                                <p className='corsivo'>
                                    *Persona Esperta in ambito elettrico (PES): persona con istruzione, conoscenza ed esperienza rilevanti tali da consentirle di
                                    analizzare i rischi e di evitare i pericoli che l’elettricità può creare.<br/>
                                    ** Persona Avvertita in ambito elettrico (PAV): persona adeguatamente avvisata da persone esperte per metterla in grado di evitare
                                    i pericoli che l’elettricità può creare.<br/>
                                    ***Persona idonea ai lavori sotto tensione (PEI): persona che oltre ad avere la qualifica di “PES” o “PAV” ha ricevuto da parte
                                    del Datore di Lavoro (nel caso di lavoratori dipendenti) l’idoneità all’esecuzione di uno o più lavori specifici sotto tensione
                                    su sistemi di Categoria 0 e 1. L’idoneità sottintende il possesso di conoscenze teoriche ed esperienza pratica
                                    nell’ambito delle attività previste e deve basarsi sull’idoneità psicofisica, curriculum professionale e comportamenti
                                    durante l’attività lavorativa svolta, con riferimento alla sicurezza.    
                                </p>                             
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', alignItems:'right', textAlign:'right', width: '100%', height: '80%', overflow: 'auto'}}>
                    <p style={{width: '100%', wordWrap:'initial', fontSize:'80%', textAlign:'justify'}}>
                    <br/><br/>
                        *Persona Esperta in ambito elettrico (PES): persona con istruzione, conoscenza ed esperienza rilevanti tali da consentirle di
                        analizzare i rischi e di evitare i pericoli che l’elettricità può creare.
                    <br/><br/>
                         ** Persona Avvertita in ambito elettrico (PAV): persona adeguatamente avvisata da persone esperte per metterla in grado di evitare
                        i pericoli che l’elettricità può creare.
                    <br/><br/>
                        ***Persona idonea ai lavori sotto tensione (PEI): persona che oltre ad avere la qualifica di “PES” o “PAV” ha ricevuto da parte
                        del Datore di Lavoro (nel caso di lavoratori dipendenti) l’idoneità all’esecuzione di uno o più lavori specifici sotto tensione
                        su sistemi di Categoria 0 e 1. L’idoneità sottintende il possesso di conoscenze teoriche ed esperienza pratica
                        nell’ambito delle attività previste e deve basarsi sull’idoneità psicofisica, curriculum professionale e comportamenti
                        durante l’attività lavorativa svolta, con riferimento alla sicurezza.
                    </p>
                </div> */}
            </div>
          </section>
        </div>
        </>
  )
}

export default partiElettriche;