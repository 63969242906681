import React from 'react'
import '../styles/Cards.css'

const fakePage = () => {
      return (
        <>
        <div >
          <section className='section bg-white'>
            <div className='container1'><br></br><br></br><br></br><br></br>
              <h1 id='tit'> In Costruzione...</h1>
            </div>
          </section>
        </div>
        </>
  )
}

export default fakePage