import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import '../../styles/Cards.css'

const patenteCrediti = () => {
    return (
        <>
        <div >
          <section className='section bg-white'>
            <div ><br></br><br></br>
                <h1 id='tit2'>Patente e crediti</h1>
                <br></br>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{width: '80%', height: '80%', textAlign: 'center'}}>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il Decreto-legge 2 marzo 2024, n.19, convertito con Legge 29 aprile 2024, n. 56, ha introdotto una importante novità nel settore dei cantieri
                        temporanei e mobili, come individuati dall’art. 89, comma 1, lett. a), del D.lgs. n. 81/2008. 
                        A partire dal 01.10.2024, infatti, nel settore edile verrà introdotto uno strumento di qualificazione delle imprese impiegate nei cantieri
                        temporali e mobili per rafforzare la tutela della salute e sicurezza  dei lavoratori che operano in questo settore.
                        Tale strumento, la patente a crediti, persegue dunque la finalità di monitorare il rispetto della normativa sulla sicurezza da parte di queste imprese. 
                        </p>
                        {/* <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il preposto per adempiere efficacemente ai propri obblighi, elencati dall'art. 19 del D.lgs. 81/08, deve ricevere un'adeguata
                         e specifica formazione e relativo aggiornamento periodico così come previsto dall'art. 37 del D.lgs. 81/08.
                        </p> */}
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%', }}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>PRESUPPOSTI PER IL RILASCIO DELLA PATENTE  </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Le imprese (per mezzo del rappresentante legale o suo delegato) e i lavoratori autonomi (in prima persona o per mezzo di delegato)
                                che operano nei citati cantieri, ad eccezione di quelli che effettuino mere forniture o prestazioni intellettuali e delle imprese
                                che siano in possesso dell’attestazione di qualificazione SOA in classifica pari o superiore alla III,
                                saranno obbligati a richiedere, attraverso il portale dell’Ispettorato Nazionale del Lavoro,
                                il rilascio della patente digitale, che rimarrà disponibile in formato digitale sul medesimo portale. 
                                L’emissione della patente consegue automaticamente alla presentazione dei seguenti documenti: 
                                <li style={{textAlign:'left', fontSize:'100%'}}>autocertificazione, ai sensi dell’art. 46 del d.P.R. n. 445/2000, dell’iscrizione alla Camera di commercio, industria, artigianato e agricoltura; </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>autocertificazione, ai sensi dell’art. 46 del d.P.R. n. 445/2000, del possesso di DURC in corso di validità; </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>autocertificazione, ai sensi dell’art. 46 del d.P.R. n. 445/2000, del possesso della certificazione di regolarità fiscale, se previsto;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>dichiarazione sostitutiva, ai sensi dell’art. 47 del d.P.R. n. 445/2000, di atto di notorietà dell’adempimento degli obblighi formativi;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>dichiarazione sostituiva, ai sensi dell’art. 47 del d.P.R. n. 445/2000, di atto di notorietà del possesso di DVR valido; </li>  
                                <li style={{textAlign:'left', fontSize:'100%'}}>dichiarazione sostituiva, ai sensi dell’art. 47 del d.P.R. n. 445/2000, di atto di notorietà della designazione del Responsabile del servizio di prevenzione e protezione (R.S.P.P.), se previsto. </li>  
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>Nei casi in cui, in sede di controllo successiva al rilascio, venga accertata la non veridicità di una o più delle dichiarazioni rese, la patente è soggetta a revoca.</p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>Decorsi 12 mesi dalla revoca, l’impresa o il lavoratore autonomo può richiedere il rilascio di una nuova patente.</p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Ulteriori conseguenze, di rilevanza penale, sono poi previste dall’art. 76 del Decreto del Presidente della Repubblica n. 445/2000 in caso di rilascio 
                                di dichiarazioni mendaci e dall’art. 48 del Codice penale.     
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Per le imprese e i lavoratori autonomi stabiliti in altri Stati membri dell’Unione Europea o in un Paese extra-UE è prevista la presentazione di un documento equivalente rilasciato dalle competenti autorità.     
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Della formale presentazione della domanda di rilascio della patente devono essere informati, entro cinque giorni dal deposito, il rappresentante dei lavoratori  per  la sicurezza  e  il  rappresentante  dei  lavoratori  per  la  sicurezza territoriale.
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Nelle more del rilascio della patente è comunque consentito lo svolgimento delle attività di cui all’art. 27, comma 1, del D.lgs. n. 81/2008, salva diversa comunicazione notificata dall'Ispettorato nazionale del lavoro.
                                </p>
                                </div>
                                </div>
                            </div>
                </div>
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>I CONTENUTI INFORMATIVI LEGATI A CIASCUNA PATENTE </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Con riferimento a ciascuna patente, sul portale saranno disponibili le seguenti informazioni: 
                                <li style={{textAlign:'left', fontSize:'100%'}}>dati identificatici del titolare (persona giuridica/imprenditore individuale/lavoratore autonomo); </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>dati anagrafici del soggetto richiedente la patente;</li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>data di rilascio e numero della patente; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>punteggio della patente al momento del rilascio; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>punteggio aggiornato alla data dell’interrogazione del portale; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>esiti di eventuali sospensioni della patente; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>esiti di eventuali provvedimenti definitivi di natura amministrativa o giurisdizionali ai quali
                                consegue una decurtazione di punti. </li> 
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                A tali informazioni possono avere accesso solo soggetti titolari di un interesse qualificato, inclusi:
                                <li style={{textAlign:'left', fontSize:'100%'}}>pubbliche Amministrazioni;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>rappresentanti dei lavoratori per la sicurezza; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>rappresentanti dei lavoratori per la sicurezza territoriale; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>organismi paritetici iscritti nel Repertorio nazionale di cui all’art. 51, comma 1 bis, del T.U.S.L.; </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>responsabili dei lavori; </li>  
                                <li style={{textAlign:'left', fontSize:'100%'}}>coordinatori per la sicurezza in fase di progettazione e di esecuzione. </li>  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>     
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>ATTRIBUZIONE DEI CREDITI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Alle imprese ed ai lavoratori autonomi verrà riconosciuta sulla patente una dotazione iniziale di 30 crediti, che potrà essere incrementata,
                                fino ad un punteggio massimo di 100 punti, in ragione della storicità dell’Azienda o in ragione di attività, investimenti o formazioni effettuati.
                                Nel dettaglio, saranno assegnati:  
                                <li style={{textAlign:'left', fontSize:'100%'}}>fino a 10 crediti in base agli anni decorsi dalla data di iscrizione alla C.C.I.A.A. al momento del rilascio della patente, ovvero
                                     <ul style={{textAlign:'left', fontSize:'100%'}}>
                                        - da 5 a 10 anni di iscrizione: 3 crediti;
                                        <br></br>
                                        - da 11 a 15 anni di iscrizione: 5 crediti;
                                        <br></br>
                                        - da 16 a 20 anni di iscrizione: 8 crediti;
                                        <br></br>
                                        - oltre 20 anni di iscrizione: 10 crediti.
                                        </ul>
                                </li>   
                                
                                <li style={{textAlign:'left', fontSize:'100%'}}>Fino a 30 crediti nell’ipotesi in cui il possessore di patente abbia effettuato attività, investimenti,
                                    formazione aggiuntive in materia di salute e sicurezza sui luoghi di lavoro nei casi di:
                                    <ul style={{textAlign:'left', fontSize:'100%'}}>
                                        - certificazione di un sistema di gestione conforme alla UNI EN ISO 45001;
                                        <br></br>
                                        - investimenti effettuati sulla formazione dei lavoratori, oltre quella obbligatoria;
                                        <br></br>
                                        - utilizzo di soluzioni tecnologicamente avanzate sulla base di intese con le parti sociali comparativamente più rappresentative;
                                        <br></br>
                                        - adozione di D.V.R. anche nei casi in cui sia possibile adottare le procedure standardizzate di cui all’art. 29, commi 6 e 6 bis, del D.lgs. n. 81/2008;
                                        <br></br>
                                        - effettuazione di almeno due visite in cantiere da parte del medico competente affiancato dal RLST o RLS. 
                                    </ul>
                                </li> 
                                <li style={{textAlign:'left', fontSize:'100%'}}>Fino a 10 crediti per attività, investimenti, formazione aggiuntivi individuati dal D.M. 132/2024, tra i quali rientrano:
                                    <ul style={{textAlign:'left', fontSize:'100%'}}>
                                        - il possesso di certificazione SOA di I e II classifica;
                                        <br></br>
                                        - l’applicazione di determinati standard contrattuali e organizzativi certificati nell’impiego di manodopera; 
                                        <br></br>
                                        - la formazione sulla lingua per lavoratori stranieri; 
                                        <br></br>
                                        - il possesso dei requisiti reputazionali valutati sulla base di indici qualitativi e quantitativi nonché su accertamenti definitivi che esprimono l’affidabilità dell’impresa in fase esecutiva, il rispetto della legalità e degli obiettivi di sostenibilità e responsabilità sociale.
                                    </ul>
                                </li>
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                I soggetti tenuti al possesso della patente possono operare nei cantieri mobili o temporanei, di cui all’art. 89, comma 1, lett. a), con una dotazione pari o superiore a 15 crediti. 
                                La patente con punteggio inferiore a 15 crediti non consente alle imprese e ai lavoratori autonomi di operare nei cantieri, ma consente il completamento delle attività oggetto di appalto o subappalto 
                                in corso di esecuzione, quando i lavori eseguiti siano superiori al 30% del valore del contratto, salva l’adozione dei provvedimenti di cui all’art. 14 del D.lgs. n. 81/2008. 
                                Le imprese o i lavoratori autonomi tenuti al possesso della patente che operino in assenza della stessa o con una patente con punteggio inferiore a 15 crediti sono soggette a sanzioni 
                                amministrative particolarmente severe, previste dal Decreto legge medesimo, ed all’esclusione dalla partecipazione ai lavori pubblici di cui al codice dei contratti pubblici (D.lgs. n. 36/2023) per un periodo di 6 mesi. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>     
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>INCREMENTO AUTOMATICO DEI CREDITI </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                In mancanza di provvedimenti di decurtazione dei crediti, la patente è incrementata di un credito per ciascun biennio successivo al rilascio della stessa, fino ad un massimo di 20 crediti. 
                                Se al titolare della patente vengono contestate una o più violazioni di cui all’Allegato I bis del D.lgs. n. 81/2008, il predetto incremento è sospeso fino alla decisione definitiva sull’impugnazione, ove proposta, 
                                salvo che, successivamente alla notifica del verbale di accertamento, il titolare della patente consegua l’asseverazione del modello di organizzazione e gestione rilasciato dall’organismo paritetico iscritto al Repertorio 
                                nazionale di cui all’art. 51 del T.U.S.L.  
                                In presenza di provvedimento definitivo che accerti una o più violazioni di cui all'Allegato I bis annesso al D.lgs. n. 81/2008, l'incremento automatico dei crediti non si applica per un periodo di tre anni   
                                decorrente dalla definitività del provvedimento.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>      
                <br/>  
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>DECURTAZIONE DEI CREDITI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Il punteggio della patente subisce decurtazioni in presenza di provvedimenti definitivi (sentenze passate in giudicato o ordinanze-ingiunzione di cui all’art. 18 della Legge n. 689/1981 divenute definitive) emanati 
                                nei confronti dei datori di lavoro, dirigenti e preposti delle imprese o dei lavoratori autonomi, nei casi e nelle misure indicati nell’allegato I bis annesso al D.lgs. n. 81/2008. 
                                Se nell’ambito di un medesimo accertamento ispettivo sono contestate più violazioni tra quelle indicate nell’allegato I bis, i crediti sono decurtati in misura non eccedente il doppio di quella prevista per la violazione più grave. 
                                Il meccanismo di decurtazione è chiaramente parametrato alla gravità della violazione realizzata dall’impresa o dal lavoratore autonomo: in caso di infortunio mortale è prevista la decurtazione di 20 crediti.   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>    
                <br/> 
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>RECUPERO DEI CREDITI </h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Il recupero dei crediti decurtati segue alla verifica, ad opera della Commissione territoriale composta da rappresentanti dell’INL e dell’INAIL, dell’effettivo assolvimento dell’obbligo di formazione in materia di salute e sicurezza sui 
                                luoghi di lavoro da parte dei soggetti responsabili di almeno una delle violazioni di cui all’allegato I bis del D.lgs. n. 81/2008, nonché dei lavoratori occupati presso il cantiere in cui si è verificata la violazione, 
                                e della realizzazione di uno o più investimenti in materia di salute e sicurezza sul lavoro come previsti dall’art. 5, comma 4, lett. A), D.M. n. 132/2024 . 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>      
                <br/>     
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>SOSPENSIONE DELLA PATENTE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                La patente può essere oggetto di sospensione, ad opera dell’Ispettorato del lavoro territorialmente competente, nei seguenti casi:
                                <li style={{textAlign:'left', fontSize:'100%'}}>quando in cantieri temporanei o mobili si verifichino infortuni da cui derivi la morte di uno o più lavoratori e l’evento sia imputabile, almeno a titolo di colpa grave, al datore di lavoro (o a suo delegato ex art. 16 D.lgs. n. 81/2008) o al dirigente.
                                 In tali ipotesi la sospensione è obbligatoria, fatta salva la diversa valutazione dell'Ispettorato adeguatamente motivata; 
                                </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>quando in cantieri temporanei o mobili si verifichino infortuni che causino inabilità permanente o menomazione irreversibile suscettibile di essere accertata immediatamente di uno o più lavoratori e l’evento sia imputabile, almeno a titolo di colpa grave, al datore di lavoro (o a suo delegato ex art. 16 D.lgs. n. 81/2008) o al dirigente.
                                 In tali ipotesi la sospensione è facoltativa e può essere adottata se le esigenze cautelari non sono soddisfatte mediante adozione del provvedimento di cui all’articolo 14 del D.lgs. n. 81/2008 o all’articolo 321 del Codice di procedura penale. 
                                </li>   
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}> La colpa grave, in particolare, si configura quando le imprese ignorino la normativa vigente in materia di sicurezza, non adottino misure di prevenzione adeguate o trascurino la formazione dei lavoratori adottando comportamenti caratterizzati da grave negligenza, imprudenza o imperizia. 
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                A titolo esemplificativo, la mancata adozione di dispositivi individuali di protezione configura un’ipotesi di colpa grave. 
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                La durata della sospensione non può essere superiore a 12 mesi ed è determinata tenendo conto della gravità degli infortuni, della gravità della violazione compiuta in materia di tutela della salute e sicurezza sui luoghi di lavoro nonché della presenza di eventuali recidive.
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Avverso il provvedimento cautelare di sospensione è ammesso il ricorso ai sensi e per gli effetti dell’art. 14, comma 14, del D.lgs. n. 81/2008.                                 
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Al termine del periodo di sospensione l’Ispettorato del lavoro territorialmente competente verifica il ripristino delle condizioni di sicurezza nel cantiere in cui si è verificata la violazione.                                
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                La sospensione della patente comporta una serie di gravi conseguenze per le imprese, tra cui il divieto di partecipare a gare di appalto nelle more del periodo di sospensione.                                 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>CONTROLLO DELLA PATENTE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Il Committente e il Responsabile dei Lavori hanno l’obbligo di verificare che le imprese operino in conformità alle norme di sicurezza: gli stessi sono dunque tenuti al controllo del possesso, da parte delle imprese operanti nel cantiere, della patente a crediti dotata di un punteggio minimo di 15 crediti (requisito indispensabile per accedere al cantiere). 
                                Il controllo andrà effettuato, chiaramente, anche nei confronti di eventuali imprese subappaltatrici. 
                                </p>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Nell’ipotesi in cui una impresa operante nel cantiere non sia tenuta al possesso della patente (es. impresa che fornisce una prestazione meramente intellettuale), il Committente o il Responsabile dei lavori devono acquisire evidenza documentale della circostanza esimente. 
                                Il mancato controllo del possesso della patente da parte delle imprese di cantiere può portare alla comminazione di sanzioni amministrative pecuniarie nei confronti del Committente e del Responsabile dei lavori, come previsto dal comma 9, lett. b-bis, dell’art. 90 del D.lgs. n. 81/2008.                                 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div style={{display:'flex', alignItems: 'center', width: '100%', height: '100%',}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '100%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center' , justifyContent:'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%', fontWeight:'bold'}}>CONCLUSIONI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%', textAlign: 'justify'}}>
                                Il sistema della patente a crediti, pensato per implementare le condizioni di tutela di salute e sicurezza sui cantieri temporali e mobili, costituisce indiscutibilmente una rivoluzione nel settore edile. 
                                Solo la concreta applicazione di questo strumento potrà dirimere le perplessità che la lettura delle fonti normative che lo disciplinano fanno sorgere e sulle quali ci riserviamo di interrogarci con contenuti specifici. 
                                Di certo c’è da sperare che il nobile intento perseguito dal legislatore sia effettivamente realizzato con l’entrata a regime di questa importante novità.                                 
                                </p>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
          </section>
        </div>
        </>
    )
}

export default patenteCrediti;