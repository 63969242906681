import React from 'react'
import HomeScreen from "../components/HomeScreen";
import Cards from '../components/Cards';
import '../styles/HomeScreen.css'
import Aziende from '../components/Aziende';

const home = () => {
  return (
      <>
      <div id="dim">
        <div>
        <HomeScreen/>
        </div>
      </div>
      <div>
        <Cards/>
      </div><br></br><br></br><br></br>
      <div id='coabs'>
        <div id='abs'>
          <h1>Chi Siamo</h1><br></br><br></br>
          Agenzia Nazionale Sicurezza sul Lavoro é una società di consulenza integrata che nasce nel
          2006 a Montesilvano-Abruzzo dalla ultraventennale esperienza maturata dal Dott. Paolo Di Lorenzo
          nel settore della sicurezza sul lavoro. I progetti e le idee nascono da un team formato da 
          professionisti del settore, tecnici specializzati, ingegneri, tecnici della prevenzione, docenti 
          abilitati e medici competenti.<br></br><br></br>
          Grazie a queste risorse possiamo rispondere alle esigenze di piccole, medie e grandi realtà aziendali
          distribuite su tutto il territorio, realtà che necessitano di un interlocutore preparato e strutturato
          per l'erogazione di un servizio completo e professionale.<br></br><br></br>
          Crediamo fermamente nella collaborazione, nella crescita e nello sviluppo delle professionalità.
          Da tempo siamo convenzionati con la Global Safety Work, UNI Ente Nazionale Italiano di Unificazione, 
          AIAS Associazione professionale italiana Ambiente e Sicurezza, Università degli Studi Gabriele d'Annunzio - Pescara,
          O.P.N. EFEI organismo paritetico nazionale.<br></br><br></br>
          Attraverso un approccio multidisciplinare, riunioni di coordinamento e un aggiornamento costante, ci poniamo come un
          facility manager nei confronti del cliente, erogando servizi a 360°.
        </div>
      </div><br></br><br></br>
      <div id='car'>
        <div id='we'>Alcuni clienti che hanno gia' scelto ANSL</div><br></br><br></br>
    </div>
      <Aziende/>
    </>
  )
}

export default home