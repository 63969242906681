import React from 'react'
import '../../styles/privacyANSL.css'
import '../../styles/Cards.css'
const prova = () => {
  return (
    // <>
    // <div className="blog-page">
    //   <header>
    //     <h1>Titolo del blog post</h1>
    //     <p className="author">Autore del blog post</p>
    //     <p className="date">Data del blog post</p>
    //   </header>
    //   <main>
    //     <p className="introduction">Introduzione al blog post</p>
    //     <p className="content">Contenuto del blog post</p>
    //   </main>
    //   <footer>
    //     <p className="tags">Tag del blog post</p>
    //     <p className="comments">Commenti del blog post</p>
    //   </footer>
    // </div>
    // </>
    <>
    <br/>
    <br/>
     <div id="privacy-policy">
      <h1 id="privacy-policy-title">Numero clienti serviti</h1>
      <p id="privacy-policy-text">Quanti clienti serve l'Agenzia Nazionale Sicurezza sul Lavoro</p>

      <p>Agenzia Nazionale Sicurezza sul Lavoro S.r.l.<br/>
      Via Saline 27 – 65015 Montesilvano (PE)<br/>
      Email: privacy@626point.it<br/>
      Tel: 085 4685704</p>
      
      <br/>
      <p>Su questo sito raccoglieremo esclusivamente i dati di contatto necessari a gestire le tue richieste di assistenza e di informazioni.</p>
      <p>Le finalità per le quali utilizziamo i tuoi dati personali sono:</p>
      <ul>
        <li id='fonst'>Contatto commerciale a seguito di azione positiva da parte del cliente.</li>
      </ul>
      <p>I dati che raccogliamo attraverso questo sito vengono processati esclusivamente in formato digitale e sono archiviati sui nostri applicativi di posta elettronica. La nostra società adotta opportune misure di sicurezza per tutelare i dati che ci hai fornito e le utilizza esclusivamente per le finalità riportate. Tutti i dati sono trattati seguendo i principi dettati dal Regolamento Europeo in materia di Protezione dei dati personali (GDPR – D.Lgs. 679/2016).</p>
      <p>I dati che ci hai fornito saranno conservati per il tempo necessario a soddisfare le tue richieste e per le operazioni relative all’interesse legittimo del Titolare. L’utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare all’indirizzo privacy@626point.it.</p>
      <p>I tuoi dati sono elaborati e conservati all’interno dei paesi UE. Non vi sono, pertanto, trasferimenti in territori extra U/E.</p>
      <p id="privacy-policy-disclaimer">SAPEVI CHE DAL 25 MAGGIO 2018 L’ADEGUAMENTO AL REGOLAMENTO PRIVACY (GDPR) E’ DIVENTATO OBBLIGATORIO PER MOLTE AZIENDE? CHIEDICI AIUTO <a href="privacy" id="privacy-policy-link">QUI</a></p>
    </div>
    </>
  )
}

export default prova