import React from 'react'
import '../../styles/Cards.css'
import attestato from '../../assets/carrellielevatori/attestato.png'
import carrello from '../../assets/carrellielevatori/carrello.png'
import documento from '../../assets/carrellielevatori/documento.png'
import lezione from '../../assets/carrellielevatori/lezione.png'
import obiettivi from '../../assets/carrellielevatori/obiettivi.png'
import omino from '../../assets/carrellielevatori/omino.png'
import persone from '../../assets/carrellielevatori/persone.png'
import posizione from '../../assets/carrellielevatori/posizione.png'
import scarpa from '../../assets/carrellielevatori/scarpa.png'
import spuntato from '../../assets/carrellielevatori/spuntato.png'
import striscione from '../../assets/carrellielevatori/Banner CORSO DI FORMAZIONE TEORICO-PRATICO PER LAVORATORI ADDETTI ALLA CONDUZIONE DI CARRELLI INDUSTRIALI .jpg'

const carrelliElevatori = () => {
    return (
        <>
        <div >
          <section className='section bg-white'>
            <div ><br></br><br></br>
                <h1 id='tit2'> Carrelli elevatori</h1>
                <div style={{display:'flex',width: '100%', height: '0%',alignItems:'center', justifyContent:'center', marginBottom:'3%'}}>
                    <img style={{color: '#035394', textAlign: 'center', width:'80%'}} src={striscione}></img>
                </div>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <h4 style={{color: '#035394', textAlign: 'center', width:'60%'}}>CORSO DI FORMAZIONE TEORICO-PRATICO PER LAVORATORI ADDETTI ALLA CONDUZIONE DI CARRELLI INDUSTRIALI SEMOVENTI</h4>
                </div>
                <br></br>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{width: '80%', height: '80%', textAlign: 'center'}}>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                            La conduzione di carrelli industriali semoventi, comunemente noti come muletti, richiede competenze specializzate e consapevolezza dei rischi associati. Sebbene possano sembrare strumenti di facile utilizzo, la loro complessità e la natura delle operazioni che devono svolgere li rendono soggetti a una serie di potenziali pericoli. Incidenti gravi, come la caduta del carico, ribaltamenti o collisioni con persone o oggetti, spesso sono attribuibili a un uso non corretto del carrello. 
                            Per queste motivazioni i carrelli industriali semoventi rientrano tra le attrezzature che per il loro impiego richiedono conoscenze o responsabilità particolari (art. 71 D.lgs. 81/2008) e per il cui utilizzo sono necessari una formazione, informazione ed addestramento adeguati e specifici, tali da consentire l’utilizzo delle attrezzature in modo idoneo e sicuro, anche in relazione ai rischi che possano essere causati ad altre persone (art.73 D.lgs. 81/2008). 
                            La formazione dei carrellisti è attualmente disciplinata dall’Accordo Stato Regioni n. 53 del 22/02/2012 che prevede che gli utilizzatori conseguano una specifica abilitazione (il cosiddetto “patentino”) rilasciata al termine della frequentazione di un apposito corso teorico-pratico.
                        </p>
                    </div>
                </div>
                <br></br><br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={carrello} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>COSA SI INTENDE PER CARRELLO INDUSTRIALE SEMOVENTE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                    Per carrello industriale semovente si intende un qualsiasi veicolo dotato di ruote (eccetto quelli circolanti su rotaie)
                                    concepito per trasportare, trainare, spingere, sollevare, impilare o disporre su scaffalature qualsiasi tipo di carico
                                    ed azionato da un operatore a bordo su sedile.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={persone} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DESTINATARI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso è destinato agli operatori addetti alla conduzione di carrelli industriali semoventi. <br></br> N.B. iI conseguimento 
                                dell’abilitazione (o patentino) è necessario anche nel caso di un utilizzo saltuario od occasionale.
                                 L’abilitazione non è invece necessaria nel caso in cui non si configuri alcuna attività lavorativa connessa all’utilizzo
                                  dell’attrezzatura. Rientrano fra dette attività il semplice spostamento a vuoto dell’attrezzatura,
                                   la manutenzione ordinaria o straordinaria, ecc. (Circolare Ministero Lavoro n. 12/2013). 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={obiettivi} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>OBIETTIVI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il percorso formativo è finalizzato all’apprendimento di tecniche operative adeguate a utilizzare in condizioni
                                di sicurezza il carrello industriale semovente.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={documento} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>PROGRAMMA DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il percorso formativo, perfettamente aderente alle indicazioni dell’Accordo Stato Regioni n. 53 del 22/02/2012, è strutturato in tre moduli.
                                <li style={{textAlign:'left', fontSize:'100%'}}>Modulo giuridico-normativo (1 ora)</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Modulo Tecnico (7 ore)</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Modulo pratico (4 ore)</li>   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                {/* <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={posizione} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>SEDE DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso si terrà interamente presso la nostra sede sita in Via O. Martelli, 4 – Montesilvano (PE)
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={scarpa} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>SEDE DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Per svolgere la prova pratica necessaria per conseguire l’attestato di abilitazione è necessario essere muniti
                                e indossare scarpe protettive.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={scarpa} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DISPOSITIVI DI PROTEZIONE INDIVIDUALE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Per svolgere la prova pratica necessaria per conseguire l’attestato di abilitazione è necessario essere muniti di
                                scarpe protettive e indossarle.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                                
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={lezione} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>METODOLOGIA DIDATTICA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                La metodologia didattica del corso sarà incentrata sull'approccio attivo, che mette al centro dell'apprendimento l'allievo stesso. 
                                Per garantire un efficace processo di insegnamento/apprendimento, adotteremo le seguenti pratiche.
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                Equilibrio tra lezioni frontali e interazioni attive. Le lezioni frontali forniranno le basi teoriche necessarie,
                                mentre saranno valorizzate e confrontate le esperienze degli allievi in aula. Inoltre, promuoveremo attività
                                di lavoro di gruppo per favorire lo scambio di conoscenze e l'apprendimento collaborativo.
                                Utilizzeremo materiali multimediali per arricchire l'esperienza di apprendimento. 
                                </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                Dimostrazioni e prove pratiche. Saranno organizzate dimostrazioni pratiche delle procedure e delle tecniche di
                                conduzione del carrello elevatore. Gli allievi avranno l'opportunità di partecipare a prove pratiche guidate
                                per mettere in pratica quanto appreso durante le lezioni teoriche. Inoltre, saranno simulate situazioni di
                                utilizzo normale e anormale dell'attrezzatura, inclusi guasti e situazioni di emergenza, per preparare gli
                                    allievi a gestire ogni eventualità con sicurezza e competenza. 
                                </li>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>         
                {/* <br></br><br></br><br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={omino} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>ESERCITAZIONE PRATICA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                L’attività pratica consiste nell’esecuzione di almeno due esercitazioni per ciascuno dei seguenti punti:
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                Guida del carrello su percorso di prova per evidenziare le corrette manovre a vuoto e a carico 
                                (corretta posizione sul carrello, presa del carico, trasporto nelle varie situazioni, sosta del carrello, ecc.).  
                                </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                    Manutenzione e verifiche giornaliere e periodiche di legge e secondo quanto indicato nelle istruzioni di
                                    uso del carrello.
                                </li> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>                */}
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={spuntato} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>VERIFICA DI APPRENDIMENTO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Al fine di verificare l’apprendimento, ogni partecipante sarà sottoposto a:
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                un test intermedio con domande a risposta multipla, che si riterrà superato con almeno il 70% di risposte
                                corrette.  
                                </li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>
                                una prova pratica finale di utilizzo del carrello industriale semovente.  
                                </li>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>   
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={attestato} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>ATTESTATO DI ABILITAZIONE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                L’esito positivo delle prove di verifica intermedia e finale, unitamente a una presenza pari ad almeno il 90% del monte ore,
                                 consente a ciascun partecipante di ricevere un attestato di abilitazione (anche chiamato patentino) conforme ai requisiti 
                                 previsti dall’Accordo Stato Regioni n. 53 del 22/02/2012.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* FINE PROVA PER DANIELE */}
                {/* <br></br><br></br><br></br>

                <div style={{display:'flex', justifyContent:'space-around', width: '100%', height: '80%'}}>    

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={carrello} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>COSA SI INTENDE PER CARRELLO INDUSTRIALE SEMOVENTE</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                            Per carrello industriale semovente si intende qualsiasi veicolo dotato di ruote (eccetto quelli circolanti su rotaie)
                            concepito per trasportare, trainare, spingere, sollevare, impilare o disporre su scaffalature qualsiasi tipo di carico
                            ed azionato da un operatore a bordo su sedile.  
                        </p>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={persone} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>CHI SONO I DESTINATARI</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                            Il corso è destinato agli operatori addetti alla conduzione di carrelli industriali semoventi.  
                            N.B. iI conseguimento dell’abilitazione (o patentino) è necessario anche nel caso di un utilizzo saltuario od occasionale. 
                            L’abilitazione non è invece necessaria nel caso in cui non si configuri alcuna attività lavorativa connessa all’utilizzo dell’attrezzatura.
                            Rientrano fra dette attività il semplice spostamento a vuoto dell’attrezzatura, la manutenzione ordinaria o straordinaria, ecc.
                            (Circolare Ministero Lavoro n. 12/2013). 
                        </p>
                    </div>
                </div>

                <br></br><br></br>

                <div style={{display:'flex', justifyContent:'space-around', width: '100%', height: '80%'}}>    

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={documento} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>PROGRAMMA DEL CORSO</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                        Il percorso formativo, perfettamente aderente alle indicazioni dell’Accordo Stato Regioni del 22/02/2012, 
                        è strutturato in tre moduli:
                        <li style={{textAlign:'left', fontSize:'100%'}}>Modulo giuridico-normativo (1 ora)</li>   
                        <li style={{textAlign:'left', fontSize:'100%'}}>Modulo Tecnico (7 ore)</li>   
                        <li style={{textAlign:'left', fontSize:'100%'}}>Modulo pratico (4 ore)</li>   
                        </p>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={scarpa} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>DISPOSITIVI DI PROTEZIONE INDIVIDUALE</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                        Per svolgere la prova pratica necessaria per conseguire l’attestato di abilitazione è necessario
                         essere muniti e indossare scarpe protettive. 
                        </p>
                    </div>
                </div>

                <br></br><br></br>

                <div style={{display:'flex', justifyContent:'space-around', width: '100%', height: '80%'}}>    

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={obiettivi} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>OBIETTIVO</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                        Il percorso formativo è finalizzato all’apprendimento di tecniche operative adeguate per utilizzare
                         in condizioni di sicurezza il carrello industriale semovente. 
                        </p>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={lezione} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>METODOLOGIA DIDATTICA</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                        La metodologia didattica del corso sarà incentrata sull'approccio attivo, che mette al centro dell'apprendimento
                         l'allievo stesso. Per garantire un efficace processo di insegnamento/apprendimento, adotteremo le seguenti pratiche: 
                         <li style={{textAlign:'left', fontSize:'100%'}}>
                         Equilibrio tra lezioni frontali e interazioni attive: Le lezioni frontali forniranno le basi teoriche necessarie,
                          mentre saranno valorizzate e confrontate le esperienze degli allievi in aula. Inoltre, promuoveremo attività
                           di lavoro di gruppo per favorire lo scambio di conoscenze e l'apprendimento collaborativo.
                         Utilizzeremo materiali multimediali per arricchire l'esperienza di apprendimento. 
                        </li>   
                        <li style={{textAlign:'left', fontSize:'100%'}}>
                        Dimostrazioni e prove pratiche: Saranno organizzate dimostrazioni pratiche delle procedure e delle tecniche di
                         conduzione del carrello elevatore. Gli allievi avranno l'opportunità di partecipare a prove pratiche guidate
                          per mettere in pratica quanto appreso durante le lezioni teoriche. Inoltre, saranno simulate situazioni di
                           utilizzo normale e anormale dell'attrezzatura, inclusi guasti e situazioni di emergenza, per preparare gli
                            allievi a gestire ogni eventualità con sicurezza e competenza. 
                        </li>
                        </p>
                    </div>
                </div>

                <br></br><br></br>

                <div style={{display:'flex', justifyContent:'space-around', width: '100%', height: '80%'}}>    

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={omino} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>ESERCITAZIONE PRATICA</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                            L’attività pratica consiste nell’esecuzione di almeno due esercitazioni per ciascuno dei seguenti punti: 
                            <li style={{textAlign:'left', fontSize:'100%'}}>
                                Guida del carrello su percorso di prova per evidenziare le corrette manovre a vuoto e a carico 
                                (corretta posizione sul carrello, presa del carico, trasporto nelle varie situazioni, sosta del carrello, ecc.).  
                            </li>   
                            <li style={{textAlign:'left', fontSize:'100%'}}>
                                Manutenzione e verifiche giornaliere e periodiche di legge e secondo quanto indicato nelle istruzioni di
                                 uso del carrello.
                            </li> 
                        </p>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={spuntato} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>VERIFICA DI APPRENDIMENTO</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                        Al fine di verifica l’apprendimento, ogni partecipante sarà sottoposto a:
                            <li style={{textAlign:'left', fontSize:'100%'}}>
                                Un test intermedio con domande a risposta multipla, che si riterranno superati con almeno il 70% di risposte
                                corrette.  
                            </li>   
                            <li style={{textAlign:'left', fontSize:'100%'}}>
                                Una prova pratica finale di utilizzo dell’attrezzatura di lavoro.  
                            </li>  
                        </p>
                    </div>
                </div>
                <br></br><br></br>

                <div style={{display:'flex', justifyContent:'space-around', width: '100%', height: '80%'}}>    

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={attestato} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>ATTESTATI DI ABILITAZIONE</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                            L’esito positivo delle prove di verifica intermedia e finale, unitamente a una presenza pari ad almeno il 90% 
                            del monte ore consente a ciascun partecipante di ricevere un attestato di abilitazione (anche chiamato patentino)
                            conforme ai requisiti previsti dall’Accordo stato Regioni sancito il 22 febbraio 2012.   
                        </p>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'center', width: '40%', height: '80%', overflow: 'auto'}}>
                        <img src={posizione} style={{display: 'block', margin: 'auto', width:'35%', height:'45%'}}></img>
                        <h6 style={{color: '#035394', textAlign: 'center'}}>DOVE</h6>
                        <p style={{textAlign:'center', width: '100%', wordWrap: 'break-word'}}>
                            Il corso si terrà presso la nostra sede sita in Via O. Martelli, 4 – Montesilvano (PE)  
                        </p>
                    </div>
                </div> */}

            </div>
          </section>
        </div>
        </>
  )
}

export default carrelliElevatori;