/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import '../styles/privacyANSL.css'
import '../styles/Cards.css'
import { Link } from 'react-router-dom';

const privacyANSL = () => {
  return (
    <>
    <br/>
    <br/>
     <div id="privacy-policy">
      <h1 id="privacy-policy-title">INFORMATIVA PRIVACY DI CUI ALL’ART. 13 DEL D.LGS. 679/2016</h1>
      <p id="privacy-policy-text">Chi è il titolare del trattamento?</p>

      <p>Agenzia Nazionale Sicurezza sul Lavoro S.r.l.<br/>
      Via O. Martelli 4 – 65015 Montesilvano (PE)<br/>
      Email: privacy@626point.it<br/>
      Tel: 085 4685704</p>
      
      <p id="privacy-policy-text">Quali dati vengono raccolti?</p>
      <p>Su questo sito raccoglieremo esclusivamente i dati di contatto necessari a gestire le tue richieste di assistenza, di informazioni nonché eventuali candidature spontanee.</p>
      <p id="privacy-policy-text">Quali sono le finalità del trattamento?</p>
      <p>Le finalità per le quali utilizziamo i tuoi dati personali sono:</p>
      <ul>
        <li id='fonst'>Gestione dei contatti commerciali provenienti dai diversi punti di contatto dislocati all’interno del sito web;</li>
        <li id='fonst'>Gestione delle candidature spontanee avvenute tramite compilazione dell’apposito form.</li>
      </ul>
      <p id="privacy-policy-text">Come vengono processati i dati?</p>
      <p>I dati che raccogliamo attraverso questo sito vengono processati esclusivamente in formato digitale e sono archiviati sui nostri applicativi di posta elettronica. La nostra società adotta opportune misure di sicurezza per tutelare i dati che ci hai fornito e le utilizza esclusivamente per le finalità riportate. Tutti i dati sono trattati seguendo i principi dettati dal Regolamento Europeo in materia di Protezione dei dati personali (GDPR – D.Lgs. 679/2016).</p>
      <p id="privacy-policy-text">Per quanto tempo vengono conservati i miei dati?</p>
      <p>I dati che ci hai fornito saranno conservati per il tempo necessario a soddisfare le tue richieste e per le operazioni relative all’interesse legittimo del Titolare. L’utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare all’indirizzo privacy@626point.it.</p>
      <p id="privacy-policy-text">I miei dati sono trasferiti all’estero?</p>
      <p>I tuoi dati sono elaborati e conservati all’interno dei paesi UE. Non vi sono, pertanto, trasferimenti in territori extra U/E.</p>
      <p id="privacy-policy-text">All’interno del sito vengono utilizzati cookies? </p>
      <p>All’interno del sito web utilizziamo cookies esclusivamente per finalità statistiche (Google Analytics). Non viene effettuata nessuna profilazione dell’utente.</p>
      <p id="privacy-policy-disclaimer">SAPEVI CHE DAL 25 MAGGIO 2018 L’ADEGUAMENTO AL REGOLAMENTO PRIVACY (GDPR) E’ DIVENTATO OBBLIGATORIO PER MOLTE AZIENDE? CHIEDICI AIUTO <a href="/privacy" id="privacy-policy-link">QUI</a></p>
    </div>
    </>
  )
}

export default privacyANSL