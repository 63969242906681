/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { CSSTransition } from 'react-transition-group';
import '../styles/Cards.css'
import '../styles/HomeScreen.css'
import info from '../assets/formazione/info.jpg'
import glav from '../assets/formazione/form.-generale-lav.-per-la-sicurezza.jpg'
import gglav from '../assets/formazione/formaagg-lav.-per-sicur..jpg'
import antic from '../assets/formazione/form.agg_.add_.antinc..jpg'
import primoso from '../assets/formazione/primo-soccorso.jpg'
import cravro from '../assets/formazione/form.agg_.-rapp.-dei-lav.-sicurezza.jpg'
import fps from '../assets/formazione/formazione-preposti-sicurezza.jpg'
import fds from '../assets/formazione/formazione-DIRIGENTI-sicurezza.jpg'
import carroponte from '../assets/formazione/carroponte.jpg'
import pms from '../assets/formazione/PREPOSTI-MONTAGGIO-SMONTAGGIO.jpg'
import elet from '../assets/formazione/ELETTRICITA.jpg'
import oto from '../assets/formazione/OTOPROTETTORI.jpg'
import ancaduta from '../assets/formazione/anticaduta.jpg'
import mafilt from '../assets/formazione/maschera-filtrante.jpg'
import funi from '../assets/formazione/funi.jpg'
import ple from '../assets/formazione/ple.jpg'
import gsa from '../assets/formazione/gru-su-autocarro.jpg'
import grt from '../assets/formazione/gru-a-torre.jpg'
import catorti from '../assets/formazione/carrelli-elevatorti.jpg'
import grma from '../assets/formazione/gru-mobili-autocarrate-e-semoventi-su-ruote.jpg'
import falc from '../assets/formazione/GRU-MOBILI-CON-FALCONE-TELESCOPICO.jpg'
import tratru from '../assets/formazione/trattori-agricoli-ruote.jpg'
import tracing from '../assets/formazione/trattori-agricoli-su-cingoli.jpg'
import escidr from '../assets/formazione/escavatore-idraulico.jpg'
import fune from '../assets/formazione/escavatori-a-fune.jpg'
import carfro from '../assets/formazione/caricatore-frontale.jpg'
import terne from '../assets/formazione/terne.jpg'
import ribcing from '../assets/formazione/autoribaltabili-a-cingoli.jpg'
import ect from '../assets/formazione/e.c.t.jpg'
import calcpo from '../assets/formazione/autopompe-per-calcestruzzo.jpg'
import rispec from '../assets/formazione/rischi-specifici.jpg'
import aconf from '../assets/formazione/ambienti-confinati.jpg'
import mali from '../assets/formazione/manipolazione-alimenti.jpg'
import rspp2 from '../assets/formazione/rspp-2.jpg'
import cse from '../assets/formazione/cse.jpg'
import qwe from '../assets/formazione/formaz-e-agg.-form.-sicurezz.jpg'
import { toast } from 'react-toastify';

const formazione = () => {
  const form = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [modal] = useState(false);
  // const [inf, setInf] = useState(['voglio essere guidato','INFORMAZIONE DEI LAVORATORI PER LA SICUREZZA','FORMAZIONE GENERALE DEI LAVORATORI PER LA SICUREZZA','FORMAZIONE SPECIFICA ED AGGIORNAMENTO DEI LAVORATORI PER LA SICUREZZA'])


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_foa7t03', 'template_1v45r88', form.current, 'TS9WbpKrWNq2gt0U_')
      .then((result) => {
          console.log(result.text);
          toast.success(' Richiesta Effettuata!', {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }, (error) => {
          console.log(error.text);
          toast.error('🦄 Wow so easy!', {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      });
      e.target.reset();
  };

    if(modal) {
      document.body.classList.add('active-modal')
    } else {
      document.body.classList.remove('active-modal')
    }
    // const notify = () => toast("Wow so easy!");

    const [a, setA] = useState(false)
    const [b] = useState(false)
    const [c, setC] = useState(false)
    const [d, setD] = useState(false)
    const [e, setE] = useState(false)
    const [f, setF] = useState(false)
    const [g, setG] = useState(false)
    const [h, setH] = useState(false)
    const [i, setI] = useState(false)
    // const [l, setL] = useState(false)
    // const [m, setM] = useState(false)
  return (
    <>
    <div >
      <section className='section bg-white h-100'>
        <div className='container1'><br></br><br></br><br></br><br></br>
          <h1 id='tit'>Formazione</h1> 
          <div>
            <div id='descrserv'>
              <p id='po'>Lavoriamo costantemente per migliorare la sicurezza nei luoghi di lavoro considerando la formazione come
               un elemento cardine della prevenzione. Attraverso i nostri corsi miriamo a far acquisire a ciascun lavoratore le competenze
                e le conoscenze necessarie per riconoscere e gestire i rischi presenti nell'ambiente lavorativo che vivono quotidianamente.</p>
            </div>
          </div>
          <div id='descrserv2'>
            {/* <button id='bssl1'>Prossimi corsi in partenza</button> */}
            <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="popup"
        unmountOnExit
        >
        <div className="popup-content ">
          <p style={{ color: "white", fontSize:'1.5rem', fontWeight:'bold'}}>Abbiamo bisogno di alcune Informazioni..</p><br></br>
          <form ref={form} onSubmit={sendEmail}>
              <div >
                <div style={{ width: "40%" }}>
                  <label htmlFor="nominativo" style={{ color: "white" }}>Il tuo Nominativo:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="nominativo" name="nominativo" required />
                </div>
                <br />
                <br />
                <div style={{ width: "90%" }}>
                  <label htmlFor="nominativo" style={{ color: "white" }}>Il Nome della tua Impresa (facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="nImpresa" name="nImpresa" />
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "40%" }}>
                  <label htmlFor="email" style={{ color: "white" }}>La tua Email:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="email" id="email" name="user_email" required />
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="provincia" style={{ color: "white" }} >In quale Provincia si trova la tua Impresa:</label>
                </div>
                <div style={{ width: "100%" }}>
                <select id="province" style={{ width: "100%" }} name='user_province' required>
                  <option value="”Agrigento”">Agrigento</option>
                  <option value="”Alessandria”">Alessandria</option>
                  <option value="”Ancona”">Ancona</option>
                  <option value="”Aosta”">Aosta</option>
                  <option value="”Arezzo”">Arezzo</option>
                  <option value="”Ascoli piceno”">Ascoli piceno</option>
                  <option value="”Asti”">Asti</option>
                  <option value="”Avellino”">Avellino</option>
                  <option value="”Bari”">Bari</option>
                  <option value="”Barletta-Andria-Trani”">Barletta-Andria-Trani</option>
                  <option value="”Belluno”">Belluno</option>
                  <option value="”Benevento”">Benevento</option>
                  <option value="”Bergamo”">Bergamo</option>
                  <option value="”Biella”">Biella</option>
                  <option value="”Bologna”">Bologna</option>
                  <option value="”Bolzano”">Bolzano</option>
                  <option value="”Brescia”">Brescia</option>
                  <option value="”Brindisi”">Brindisi</option>
                  <option value="”Cagliari”">Cagliari</option>
                  <option value="”Caltanissetta”">Caltanissetta</option>
                  <option value="”Campobasso”">Campobasso</option>
                  <option value="”Carbonia-Iglesias”">Carbonia-Iglesias</option>
                  <option value="”Caserta”">Caserta</option>
                  <option value="”Catania”">Catania</option>
                  <option value="”Catanzaro”">Catanzaro</option>
                  <option value="”Chieti”">Chieti</option>
                  <option value="”Como”">Como</option>
                  <option value="”Cosenza”">Cosenza</option>
                  <option value="”Cremona”">Cremona</option>
                  <option value="”Crotone”">Crotone</option>
                  <option value="”Cuneo”">Cuneo</option>
                  <option value="”Enna”">Enna</option>
                  <option value="”Fermo”">Fermo</option>
                  <option value="”Ferrara”">Ferrara</option>
                  <option value="”Firenze”">Firenze</option>
                  <option value="”Foggia”">Foggia</option>
                  <option value="”Forli’-Cesena”">Forli’-Cesena</option>
                  <option value="”Frosinone”">Frosinone</option>
                  <option value="”Genova”">Genova</option>
                  <option value="”Gorizia”">Gorizia</option>
                  <option value="”Grosseto”">Grosseto</option>
                  <option value="”Imperia”">Imperia</option>
                  <option value="”Isernia”">Isernia</option>
                  <option value="”la Spezia”">la Spezia</option>
                  <option value="”l’Aquila”">l’Aquila</option>
                  <option value="”Latina”">Latina</option>
                  <option value="”Lecce”">Lecce</option>
                  <option value="”Lecco”">Lecco</option>
                  <option value="”Livorno”">Livorno</option>
                  <option value="”Lodi”">Lodi</option>
                  <option value="”Lucca”">Lucca</option>
                  <option value="”Macerata”">Macerata</option>
                  <option value="”Mantova”">Mantova</option>
                  <option value="”Massa-Carrara”">Massa-Carrara</option>
                  <option value="”Matera”">Matera</option>
                  <option value="”Medio Campidano”"> Medio Campidano</option>
                  <option value="”Messina”">Messina</option>
                  <option value="”Milano”">Milano</option>
                  <option value="”Modena”">Modena</option>
                  <option value="”Monza e della Brianza”">Monza e della Brianza</option>
                  <option value="”Napoli”">Napoli</option>
                  <option value="”Novara”">Novara</option>
                  <option value="”Nuoro”">Nuoro</option>
                  <option value="”Ogliastra”">Ogliastra</option>
                  <option value="”Olbia-Tempio”">Olbia-Tempio</option>
                  <option value="”Oristano”">Oristano</option>
                  <option value="”Padova”">Padova</option>
                  <option value="”Palermo”">Palermo</option>
                  <option value="”Parma”">Parma</option>
                  <option value="”Pavia”">Pavia</option>
                  <option value="”Perugia”">Perugia</option>
                  <option value="”Pesaro e Urbino”">Pesaro e Urbino</option>
                  <option value="”Pescara”">Pescara</option>
                  <option value="”Piacenza”">Piacenza</option>
                  <option value="”Pisa”">Pisa</option>
                  <option value="”Pistoia”">Pistoia</option>
                  <option value="”Pordenone”">Pordenone</option>
                  <option value="”Potenza”">Potenza</option>
                  <option value="”Prato”">Prato</option>
                  <option value="”Ragusa”">Ragusa</option>
                  <option value="”Ravenna”">Ravenna</option>
                  <option value="”Reggio di Calabria”">Reggio di Calabria</option>
                  <option value="”Reggio nell’Emilia”">Reggio nell’Emilia</option>
                  <option value="”Rieti”">Rieti</option>
                  <option value="”Rimini”">Rimini</option>
                  <option value="”Roma”">Roma</option>
                  <option value="”Rovigo”">Rovigo</option>
                  <option value="”Salerno”">Salerno</option>
                  <option value="”Salerno”">Salerno</option>
                  <option value="”Savona”">Savona</option>
                  <option value="”Siena”">Siena</option>
                  <option value="”Siracusa”">Siracusa</option>
                  <option value="”Sondrio”">Sondrio</option>
                  <option value="”Taranto”">Taranto</option>
                  <option value="”Teramo”">Teramo</option>
                  <option value="”Terni”">Terni</option>
                  <option value="”Torino”">Torino</option>
                  <option value="”Trapani”">Trapani</option>
                  <option value="”Trento”">Trento</option>
                  <option value="”Treviso”">Treviso</option>
                  <option value="”Trieste”">Trieste</option>
                  <option value="”Udine”">Udine</option>
                  <option value="”Varese”">Varese</option>
                  <option value="”Venezia”">Venezia</option>
                  <option value="”Verbano-Cusio-Ossola”">Verbano-Cusio-Ossola</option>
                  <option value="”Vercelli”">Vercelli</option>
                  <option value="”Verona”">Verona</option>
                  <option value="”Vibo Valentia”">Vibo Valentia</option>
                  <option value="”Vicenza”">Vicenza</option>
                  <option value="”Viterbo”">Viterbo</option>
              </select>
                  {/* <input type="text" id="provincia" name="provincia" /> */}
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="provincia" style={{ color: "white" }}>Sono interessato a:</label>
                </div>
                <div style={{ width: "100%" }}>
                <select id="province" style={{ width: "100%" }} name='user_interes' required>
                  <option value="”voglio essere guidato”">VOGLIO ESSERE GUIDATO</option>
                  <option value="”INFORMAZIONE DEI LAVORATORI PER LA SICUREZZA”">informazione dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE GENERALE DEI LAVORATORI PER LA SICUREZZA”">formazione generale dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE SPECIFICA ED AGGIORNAMENTO DEI LAVORATORI PER LA SICUREZZA”">formazione specifica ed aggiornamento dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE, AGGIORNAMENTO ED ADDESTRAMENTO PER RESPONSABILI DELL'ATTUAZIONE DELLE MISURE DI EMERGENZA ANTINCENDIO”">formazione, aggiornamento ed addestramento per responsabili dell'attuazione delle misure di emergenza antincendio</option>
                  <option value="”FORMAZIONE, AGGIORNAMENTO ED ADDESTRAMENTO PER RESPONSABILI DELL'ATTUAZIONE DELLE MISURE DI PRIMO SOCCORSO”">formazione, aggiornamento ed addestramento per responsabili dell'attuazione delle misure di primo soccorso</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO PER RAPPRESENTANTI DEI LAVORATORI PER LA SICUREZZA”">Formazione ed aggiornamento per rappresentanti dei lavoratori per la sicurezza</option>
                  <option value="”FORMAZIONE PER PREPOSTI ALLA SICUREZZA”">formazione per preposti alla sicurezza</option>
                  <option value="”FORMAZIONE PER DIRIGENTI PER LA SICUREZZA”">formazione per dirigenti per la sicurezza</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARROPONTI”">formazione ed addestramento per addetti all'utilizzo di carroponti</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI E PREPOSTI AL MONTAGGIO,SMONTAGGIO E TRASFORMAZIONE DEI PONTEGGI”">formazione ed addestramento per addetti e preposti al montaggio, smontaggio e trasformazione dei ponteggi</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI AD OPERARE SU PARTI ELETTRICHE”">formazione ed addestramento per addetti ad operare su parti elettriche</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI OTOPROTETTORI”">formazione ed addestramento per l'utilizzo di otoprotettori</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI SISTEMI ANTICADUTA”">formazione ed addestramento per l'utilizzo di sistemi anticaduta</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI MASCHERE FILTRANTI”">formazione ed addestramento per l'utilizzo di maschere filtranti</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI SISTEMI DI ACCESSO E POSIZIONAMENTO FUNI”">formazione ed addestramento per l'utilizzo di sistemi di accesso e posizionamento funi</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI PIATTAFORME ELEVABILI (PLE)”">formazione ed addestramento per addetti all'utilizzo di piattaforme elevabili (PLE)</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU SU AUTOCARRO”">formazione ed addestramento per addetti all'utilizzo di gru su autocarro</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU A TORRE”">formazione ed addestramento per addetti all'utilizzo di gru a torre</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARRELLI ELEVATORI”">formazione ed addestramento per addetti all'utilizzo di carrelli elevatori</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU MOBILI AUTOCARRATE E SEMOVENTI SU RUOTE”">formazione ed addestramento per addetti all'utilizzo di gru mobili autocarrate e semoventi su ruote</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU MOBILI CON FALCONE TELESCOPICO E BRANDEGGIABILE”">formazione ed addestramento per addetti all'utilizzo di gru mobili con falcone telescopico e brandeggiabile</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TRATTORI AGRICOLI O FORESTALI SU RUOTE”">formazione ed addestramento per addetti all'utilizzo di trattori agricoli o forestali su ruote</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TRATTORI AGRICOLI O FORESTALI SU CINGOLI”">formazione ed addestramento per addetti all'utilizzo di trattori agricoli o forestali su cingoli</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI IDRAULICI”">formazione ed addestramento per addetti all'utilizzo di escavatori idraulici</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI A FUNE”">formazione ed addestramento per addetti all'utilizzo di escavatori a fune</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARICATORI FRONTALI”">formazione ed addestramento per addetti all'utilizzo di caricatori frontali</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TERNE”">formazione ed addestramento per addetti all'utilizzo di terne</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI AUTORIBALTABILI A CINGOLI”">formazione ed addestramento per addetti all'utilizzo di autoribaltabili a cingoli</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI-CARICATORI-TERNE”">formazione ed addestramento per addetti all'utilizzo di escavatori-caricatori-terne</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI AUTOPOMPE PER CALCESTRUZZO”">formazione ed addestramento per addetti all'utilizzo di autopompe per calcestruzzo</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER RISCHI SPECIFICI”">formazione ed addestramento per rischi specifici</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER LAVORI IN AMBIENTI CONFINATI O CON SOSPETTO DI INQUINAMENTO”">formazione ed addestramento per lavori in ambienti confinati o con sospetto di inquinamento</option>
                  <option value="”FORMAZIONE ED ADDESTRAMENTO PER LA MANIPOLAZIONE DEGLI ALIMENTI”">formazione ed addestramento per la manipolazione degli alimenti</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO ABILITANTE ALLA FUNZIONE DI RESPONSABILI DEL SERVIZIO DI PREVENZIONE E PROTEZIONE”">formazione ed aggiornamento abilitante alla funzione di responsabili del servizio di prevenzione e protezione</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO ABILITANTE ALLA FUNZIONE DI COORDINATORE DELLA SICUREZZA NEI CANTIERI”">formazione ed aggiornamento abilitante alla funzione di coordinatore della sicurezza nei cantieri</option>
                  <option value="”FORMAZIONE ED AGGIORNAMENTO PER FORMATORI DELLA SICUREZZA”">formazione ed aggiornamento per formatori della sicurezza</option>
              </select>
                  {/* <input type="text" id="provincia" name="provincia" /> */}
                </div>
                {/* <Multiselect id='province' name='user_interes' style={{ width: "100%" }} className='bg-white'
                  isObject={false}
                  onRemove={function noRefCheck(){}}
                  onSearch={function noRefCheck(){}}
                  onSelect={function noRefCheck(){}}
                  options={inf}
                /> */}
              </div>
              <br/>
              <br/>
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="settore" style={{ color: "white" }}>Aggiungi richieste (facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <textarea style={{ width: "100%" }} name='user_mot'></textarea>
                </div>
              </div>
              <div >
              <br/>
              <br/>
                <div style={{ width: "90%" }}>
                  <label htmlFor="settore" style={{ color: "white" }}>In che Settore opera la tua Impresa:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="settore" name="settore" />
                </div>
              </div>
              <br />
              <div style={{ fontWeight:'bold', color:'white', fontSize:'13px' }}>
              Tratteremo i tuoi dati nel pieno rispetto dei principi dettati dal Regolamento Europeo in materia di protezione dei dati personali (GDPR) .
               <br/>Cliccando su "Invia richiesta" accetti la nostra Informativa<a href='#'style={{ fontWeight:'bold', color:'white', fontSize:'13px' }}>(consultabile qui).</a>
              </div>
              <br />
              <button type="submit" value="Invia richiesta" id='bssl2'>Richiesta Effettuata</button>
            </form>
        </div> 
      </CSSTransition>
          </div>
          <div id='descrserv3'>
            unisciti anche tu
          </div>
          <div className='cards'>
            {
              <div className='card'>
                <img src={info} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>INFORMAZIONE DEI LAVORATORI PER LA SICUREZZA</h3><br></br><br></br>
                {
                  a?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setA(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setA(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={glav} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE GENERALE DEI LAVORATORI PER LA SICUREZZA</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={gglav} id='cardimg'>{}</img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE SPECIFICA ED AGGIORNAMENTO DEI LAVORATORI PER LA SICUREZZA</h3><br></br><br></br>
                {
                  d?<><p id='pcol'>
                    <ul>
                      <li>
                      PER ATTIVITÁ CON RISCHIO BASSO
                      </li>
                      <li>
                      PER ATTIVITÁ CON RISCHIO MEDIO
                      </li>
                      <li>
                      PER ATTIVITÁ CON RISCHIO ALTO
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'><br></br><br></br>
              <div> 
                <button id='bssl'  onClick={()=>setD(!d)}>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={antic} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE, AGGIORNAMENTO ED ADDESTRAMENTO PER
                 RESPONSABILI DELL'ATTUAZIONE DELLE MISURE DI EMERGENZA ANTINCENDIO</h3><br></br><br></br>
                {
                  a?<><p id='pcol'>
                    <ul>
                      <li>
                      PER ATTIVITÁ CON RISCHIO BASSO
                      </li>
                      <li>
                      PER ATTIVITÁ CON RISCHIO MEDIO
                      </li>
                      <li>
                      PER ATTIVITÁ CON RISCHIO ALTO
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setA(!a)}>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={primoso} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'><a href='/blsd' style={{fontSize:'100%'}}>FORMAZIONE E AGGIORNAMENTO PER BASIC LIFE SUPPORT AND DEFIBRILLATION (BLS-D)</a></h3><br></br><br></br>
                {
                  c?<><p id='pcol'>
                    <ul>
                      <li>
                      PER ATTIVITÁ DI CATEGORIA A
                      </li>
                      <li>
                      PER ATTIVITÁ DI CATEGORIA B o C
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setC(!c)}>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={cravro} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED AGGIORNAMENTO PER RAPPRESENTANTI DEI LAVORATORI PER LA SICUREZZA</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={fps} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'><a href='/preposti' style={{fontSize:'100%'}}>FORMAZIONE PER PREPOSTI ALLA SICUREZZA</a></h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={fds} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE PER DIRIGENTI PER LA SICUREZZA</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={carroponte} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARROPONTI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={pms} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI E PREPOSTI AL MONTAGGIO,SMONTAGGIO
                                 E TRASFORMAZIONE DEI PONTEGGI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={elet} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'><a href='/parti-elettriche' style={{fontSize:'100%'}}>FORMAZIONE PER ADDETTI AI LAVORI ELETTRICI(PES-PAV-PEI) - NORMA CEI 11 - 27 V EDIZIONE 2021</a></h3><br></br><br></br>
                {/* {
                  e?<><p id='pcol'>
                    <ul>
                      <li>
                      PER PERSONE ESPERTE (PES)
                      </li>
                      <li>
                      PER PERSONE AVVERTITE (PAV)
                      </li>
                      <li>
                      PER PERSONE IDONEE (PEI)
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                } */}
            {/* <div id='alb'>
            <div> 
                <button id='bssl'><a href='/parti-elettriche' id='bssl'>dettagli</a></button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={oto} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI OTOPROTETTORI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={ancaduta} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI SISTEMI ANTICADUTA</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={mafilt} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI MASCHERE FILTRANTI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={funi} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER L'UTILIZZO DI SISTEMI DI ACCESSO E POSIZIONAMENTO FUNI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={ple} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI PIATTAFORME ELEVABILI (PLE)</h3><br></br><br></br>
                {
                  f?<><p id='pcol'>
                    <ul>
                      <li>
                      SU STABILIZZATORI
                      </li>
                      <li>
                      SENZA STABILIZZATORI
                      </li>
                      <li>
                      CON SENZA STABILIZZATORE
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setF(!f)}>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={gsa} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU SU AUTOCARRO</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={grt} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU A TORRE</h3><br></br><br></br>
                {
                  g?<><p id='pcol'>
                    <ul>
                      <li>
                      CON ROTAZIONE IN BASSO
                      </li>
                      <li>
                      CON ROTAZIONE IN ALTO
                      </li>
                      <li>
                      CON ROTAZIONE IN ALTO ED IN BASSO
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setG(!g)}>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={catorti} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'><a href='/carrelli-elevatori' style={{fontSize:'100%'}}>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARRELLI ELEVATORI</a></h3><br></br><br></br>
                {
                  h?<><p id='pcol'>
                    <ul>
                      <li>
                      CARRELLI SEMOVENTI INDUSTRIALI
                      </li>
                      <li>
                      CARRELLI SEMOVENTI A BRACCIO TELESCOPICO
                      </li>
                      <li>
                      CARRELLI SEMOVENTI TELESCOPICI ROTATIVI
                      </li>
                      <li>
                      CARRELLI SEMOVENTI INDUSTRIALI A BRACCIO TELESCOPICO E ROTATIVI
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={grma} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI GRU MOBILI
                                 AUTOCARRATE E SEMOVENTI SU RUOTE</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={falc} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI 
                GRU MOBILI CON FALCONE TELESCOPICO E BRANDEGGIABILE</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={tratru} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TRATTORI AGRICOLI O FORESTALI SU RUOTE</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={tracing} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TRATTORI AGRICOLI O FORESTALI SU CINGOLI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={escidr} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI IDRAULICI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={fune} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI A FUNE</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={carfro} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI CARICATORI FRONTALI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={terne} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI TERNE</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={ribcing} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI AUTORIBALTABILI A CINGOLI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={ect} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI ESCAVATORI-CARICATORI-TERNE</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={calcpo} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER ADDETTI ALL'UTILIZZO DI AUTOPOMPE PER CALCESTRUZZO</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={rispec} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER RISCHI SPECIFICI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={aconf} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER LAVORI IN AMBIENTI CONFINATI O CON SOSPETTO DI INQUINAMENTO</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <img src={mali} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>FORMAZIONE ED ADDESTRAMENTO PER LA MANIPOLAZIONE DEGLI ALIMENTI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <div className=' w-100 h-auto'>
                <img src={rspp2} id='cardimg'></img><br></br><br></br>
                </div>
                <h3 id='tecard'>FORMAZIONE ED AGGIORNAMENTO ABILITANTE ALLA FUNZIONE DI RESPONSABILI DEL SERVIZIO DI PREVENZIONE E PROTEZIONE</h3><br></br><br></br>
                {
                  i?<><p id='pcol'>
                    <ul>
                      <li>
                      PER DATORI DI LAVORO
                      </li>
                      <li>
                      PER PROFESSIONISTI (CATEGORIA A-B-C)
                      </li>
                    </ul>
                    </p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setI(!i)}>dettagli</button>
              </div><br></br>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <div className=' w-100 h-auto'>
                <img src={cse} id='cardimg'></img><br></br><br></br>
                </div>
                <h3 id='tecard'>FORMAZIONE ED AGGIORNAMENTO ABILITANTE ALLA FUNZIONE DI COORDINATORE DELLA SICUREZZA NEI CANTIERI</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
            {
              <div className='card'>
                <div className=' w-100 h-auto'>
                <img src={qwe} id='cardimg'></img><br></br><br></br>
                </div>
                <h3 id='tecard'>FORMAZIONE ED AGGIORNAMENTO PER FORMATORI DELLA SICUREZZA</h3><br></br><br></br>
                {
                  b?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                }
            {/* <div id='alb'>
              <div> 
                <button id='bssl'  onClick={()=>setB(true)}>Mostra dettagli</button>
              </div><br></br>
              <div>
                <button id='bssl' onClick={()=>setB(false)}>Nascondi</button>
              </div>
            </div> */}
              </div>
            }
          </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default formazione