/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
// import car2 from '../assets/carousel.png'
import '../styles/Cards.css'

const Cards = () => {
  const [cards] = useState([
    {
      title: 'Numero Clienti Serviti ',
      text:'1.400+',  
    },
    {
      title: 'Istituzioni Pubbliche seguite',
      text:'60+',   
    },
    {
      title: 'Visite Mediche effettuate',
      text:'10.000+',
    },
    {
      title: 'Controlli effettuati dalla ANSL',
      text:'5.000+',     
    },
    {
      title: 'Anni di Esperienza',
      text:'20+',     
    },
    {
      title: 'Servizi All Inclusive',
      text:'360°',      
    },
    {
      title: 'Innovazione ',
      text:'100%',      
    },
    {
      title: 'Lavoratori Formati',
      text:'20.000+',  
    }
  ])
  return (
    <>
    <div >
      <section className='section bg-white'>
        <div className='container'><br></br><br></br><br></br><br></br>
          <h1 id='tit'> Perché Sceglierci?</h1>
          <div className='cards'>
            {
              cards.map((card, i) => (
              <div key={i} className='card'>
                <h3 id='tecard'>{card.title}</h3><br></br><br></br>
                <p id='pcol'>{card.text}</p><br></br><br></br><br></br>
              </div>
              ))
            }
          </div>
        </div>
      </section>
    </div>
    </>
  )
}

export default Cards