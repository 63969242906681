/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
import '../styles/Cards.css'
// import ac from '../assets/ambiente/acustica-ambientale.jpg'
import bg from '../assets/bg.jpg'
import Fuse from 'fuse.js';
import info from '../assets/formazione/cantiere.jpg'
import prova from '../pages/blogPages/prova'

const blog = () => {
    const [ca] = useState([
        {
          image: {bg},
          title: 'Numero Clienti Serviti ',
          text:'1.400+',
          link: 'prova'
        },
        {
          title: 'Istituzioni Pubbliche seguite',
          text:'60+',   
        },
        {
          title: 'Visite Mediche effettuate',
          text:'10.000+',
        },
        {
          title: 'Controlli effettuati dalla ANSL',
          text:'5.000+',     
        },
        {
          title: 'Anni di Esperienza',
          text:'20+',     
        },
        {
          title: 'Servizi All Inclusive',
          text:'360°',      
        },
        {
          title: 'Innovazione ',
          text:'100%',      
        },
        {
          title: 'Lavoratori Formati',
          text:'20.000+',  
        },

      ])
      const [searchTerm, setSearchTerm] = useState("");
      const [searchResults, setSearchResults] = useState([]);
      const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
      const fuse = new Fuse(ca, {
        keys: ["title", "text"],
        threshold: 0.3,
      });
      useEffect(() => {
        const results = fuse.search(searchTerm);
        setSearchResults(results);
      }, [searchTerm, ca]);
      return (
        <>
        <div >
          <section className='section bg-white'>
            <div className='container1'><br></br><br></br>
              <h1 id='tit'>BLOG</h1>
              <div className='cards'>
                {
                  <div className='cardBlog'><a href='/patente-e-crediti' style={{fontSize:'100%'}}>
                    <img src={info} id='cardimg'></img><br></br><br></br>
                    <h3 id='tecard2'>LA PATENTE A CREDITI</h3><br></br>
                      <p id='pcol2' style={{textAlign: 'justify'}}>Dal 1° ottobre 2024 le imprese e i lavoratori autonomi che operano nei cantieri temporali e mobili, di cui all'art.
                      89, comma 1, lett. a), del Decreto legislativo 9 aprile 2008, n. 81, saranno tenuti al possesso della patente a crediti. Scopriamo insieme di cosa si tratta e come funziona</p><br></br><br></br><br></br>
                      </a>
                  </div>
                }
              </div>
            </div>
          </section>
        </div>
        </>
      )
}
export default blog