/* eslint-disable jsx-a11y/anchor-is-valid */
import React  from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Navbar.css'
import occhio from '../assets/solo-occhio.png'
import { FaFacebookSquare, FaLinkedin, FaHome, FaPortrait, FaPhoneAlt, FaBars } from 'react-icons/fa'


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div id='contnav' className=' container-fluid'>
        <div className='item1 it'><Link to='/' className=' text-decoration-none'><FaHome id='o'/>Home</Link></div>
        <div className='itemspec it dropdown'>
          <div onClick={() => setIsOpen(!isOpen)} className=' dropbtn ' ><FaPortrait id='o'/>Servizi
            <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
              <Link to='/sicurezza-sul-lavoro' className=' text-decoration-none'>Sicurezza sul Lavoro</Link>
              <Link to='/cantieristica' className=' text-decoration-none'>Cantieristica</Link>
              <Link to='/sistemi-di-gestione' className=' text-decoration-none'>Sistemi di Gestione</Link>
              <Link to='/formazione' className=' text-decoration-none'>Formazione</Link>
              <Link to='/controlli-e-verifiche' className=' text-decoration-none'>Controlli e Verifiche</Link>
              <Link to='/progettazione-e-certificazione' className=' text-decoration-none'>Progettazione e Certificazione</Link>
              <Link to='/finanziamenti' className=' text-decoration-none'>Finanziamenti</Link>
              <Link to='/facility-managment' className=' text-decoration-none'>Facility Managment</Link>
              <Link to='/direttiva-macchine' className=' text-decoration-none'>Direttiva Macchine</Link>
              <Link to='/privacy' className=' text-decoration-none'>Privacy</Link>
              <Link to='/ambiente' className=' text-decoration-none'>Ambiente</Link>
              <Link to='/igiene-degli-alimenti' className=' text-decoration-none'>Igiene degli Alimenti</Link>
              <Link to='/sicurezza-eventi' className=' text-decoration-none'>Sicurezza Eventi</Link>
              <Link to='/servizi-sanitari' className=' text-decoration-none'>Servizi Sanitari</Link>
            </div>
          </div>
        </div>
        <div className='itemspec it dropdown'>
          <div onClick={() => setIsOpen(!isOpen)} className=' dropbtn '><FaBars id='o'/>Altro
            <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
              <Link to='/contact' className=' text-decoration-none'>Contattaci</Link>
              <Link to='/blog' className=' text-decoration-none'>Blog</Link>
            </div>
        </div>
        </div>
        <div className='item2 it'><a href='https://www.facebook.com/anslpage'><FaFacebookSquare className='n'/></a></div>
        <div className='item3 it'><a href='https://www.linkedin.com/company/agenzia-nazionale-sicurezza-sul-lavoro/'><FaLinkedin className='n'/></a></div>
        <div className='item0 it'><img src={occhio} alt='ANSL' className='occhio' title='ANSL' /></div>
      </div>
    </>
  )
}

export default Navbar