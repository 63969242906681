/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import '../styles/Footer.css'
import { MDBFooter } from 'mdb-react-ui-kit';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <MDBFooter id='ft' className='text-center text-lg-left position-relative'>
      <div id='gg' className=' text-center'>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='align-content-center' id='ac' href=''>
          Agenzia Nazionale Sicurezza Sul Lavoro S.r.l.
        </a>
        <p className=' align-content-center'>Via Saline, 27 65015 Montesilvano PE - Abruzzo</p>
        <Link to='/privacy-sito' className='simone text-white'>Privacy</Link>-
        <Link to='/blog' className='simone text-white'>Blog</Link>
        <p className='benefici'>“La nostra società ha ricevuto benefici rientrati nel regime degli aiuti di Stato e nel regime de minimis per i quali sussiste l’obbligo di pubblicazione<br/> del Registro Nazionale degli Aiuti di Stato di cui all’art.52 del L.234/2012. Per maggiori informazioni accedere al sito RNA.”</p>
        {/* <div className='it2'>
          <a href='https://www.facebook.com/anslpage'><FaFacebookSquare className='l'/></a>
          <a href='https://www.linkedin.com/company/agenzia-nazionale-sicurezza-sul-lavoro/'><FaLinkedin className='l'/></a>
        </div> */}
      </div>
    </MDBFooter>
  )
}

export default Footer