import React from 'react'
import '../../styles/Cards.css'
import attestato from '../../assets/carrellielevatori/attestato.png'
import carrello from '../../assets/carrellielevatori/carrello.png'
import documento from '../../assets/carrellielevatori/documento.png'
import lezione from '../../assets/carrellielevatori/lezione.png'
import obiettivi from '../../assets/carrellielevatori/obiettivi.png'
import omino from '../../assets/carrellielevatori/omino.png'
import persone from '../../assets/carrellielevatori/persone.png'
import posizione from '../../assets/carrellielevatori/posizione.png'
import scarpa from '../../assets/carrellielevatori/scarpa.png'
import spuntato from '../../assets/carrellielevatori/spuntato.png'
import striscione from '../../assets/preposti/Banner preposti.jpg'

const carrelliElevatori = () => {
    return (
        <>
        <div >
          <section className='section bg-white'>
            <div ><br></br><br></br>
                <h1 id='tit2'> Preposti</h1>
                <div style={{display:'flex',width: '100%', height: '0%',alignItems:'center', justifyContent:'center', marginBottom:'3%'}}>
                    <img style={{color: '#035394', textAlign: 'center', width:'80%'}} src={striscione}></img>
                </div>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <h4 style={{color: '#035394', textAlign: 'center', width:'60%'}}>FORMAZIONE PER PREPOSTI ALLA SICUREZZA</h4>
                </div>
                <br></br>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{width: '80%', height: '80%', textAlign: 'center'}}>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il ruolo del preposto è cruciale all'interno delle imprese e delle organizzazioni, poiché rappresenta una delle tre figure
                        fondamentali previste dal Testo Unico sulla Sicurezza sul Lavoro, insieme al datore di lavoro e al dirigente. La normativa
                        e la giurisprudenza nazionali attribuiscono al preposto una posizione di garanzia autonoma in ragione della rilevanza del ruolo,
                        che si concretizza in una attività di sovrintendenza e controllo dell'attività lavorativa e a cui sono ricondotte un funzionale
                        potere di iniziativa e una responsabilità diretta. 
                        </p>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il preposto per adempiere efficacemente ai propri obblighi, elencati dall'art. 19 del D.lgs. 81/08, deve ricevere un'adeguata
                         e specifica formazione e relativo aggiornamento periodico così come previsto dall'art. 37 del D.lgs. 81/08.
                        </p>
                    </div>
                </div>
                <br></br><br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={persone} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DESTINATARI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso è rivolto ai preposti così come definiti dall'art. 2 comma 1 lettera e) del D.Lgs. 81/08, ovvero: persona che,
                                in ragione delle competenze professionali e nei limiti di poteri gerarchici e funzionali adeguati alla natura
                                dell’incarico conferitogli, sovrintende alla attività lavorativa e garantisce l’attuazione delle direttive ricevute,
                                controllandone la corretta esecuzione da parte dei lavoratori ed esercitando un funzionale potere di iniziativa. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={obiettivi} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>OBIETTIVI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il percorso formativo è progettato per fornire ai preposti le conoscenze e le competenze necessarie per svolgere
                                efficacemente i compiti di loro competenza in materia di salute e sicurezza sul lavoro.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={documento} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>PROGRAMMA DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il percorso formativo, della durata complessiva di otto ore, è perfettamente aderente ai dettami dell'Accordo Stato-Regioni
                                e Province autonome di Trento e Bolzano del 21/12/2011. 
                                I contenuti della formazione, in relazione agli obblighi previsti dall'art. 19 del D.Lgs. 81/08, comprendono: 
                                <li style={{textAlign:'left', fontSize:'100%'}}>Principali soggetti del sistema di prevenzione aziendale: compiti, obblighi, responsabilità;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Relazioni tra i vari soggetti interni ed esterni del sistema di prevenzione;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Definizione e individuazione dei fattori di rischio;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Incidenti e infortuni mancati;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Tecniche di comunicazione e sensibilizzazione dei lavoratori, in particolare neoassunti, somministrati, stranieri;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Valutazione dei rischi dell’azienda, con particolare riferimento al contesto in cui il preposto opera;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Individuazione misure tecniche, organizzative e procedurali di prevenzione e protezione;</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Modalità di esercizio della funzione di controllo dell’osservanza da parte dei lavoratori
                                 delle disposizioni di legge e aziendali in materia di salute e sicurezza sul lavoro, e di uso dei mezzi di protezione collettivi e
                                 individuali messi a loro disposizione.
                                </li>   
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={scarpa} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DISPOSITIVI DI PROTEZIONE INDIVIDUALE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Per svolgere la prova pratica necessaria per conseguire l’attestato di abilitazione è necessario essere muniti di
                                scarpe protettive e indossarle.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                                
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={lezione} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>METODOLOGIA DIDATTICA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                La metodologia didattica del corso sarà incentrata sull'approccio attivo, che mette al centro dell'apprendimento l'allievo stesso.
                                Per garantire un efficace processo di insegnamento/apprendimento, bilanceremo lezioni frontali e interazioni attive.
                                Le lezioni frontali forniranno le basi teoriche necessarie, mentre si darà valore alle esperienze degli allievi in aula,
                                 facilitando il confronto. Utilizzeremo materiali multimediali per arricchire l'esperienza di apprendimento.  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>           
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={attestato} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>ATTESTATO DI FREQUENZA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                L’esito positivo della prova di verifica finale obbligatoria, unitamente a una presenza pari al 90% del monte ore, consente a ciascun partecipante
                                 di ricevere un attestato di frequenza.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
        </div>
        </>
  )
}

export default carrelliElevatori;