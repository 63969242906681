import React from 'react'
import '../../styles/Cards.css'
import attestato from '../../assets/carrellielevatori/attestato.png'
import carrello from '../../assets/carrellielevatori/carrello.png'
import documento from '../../assets/carrellielevatori/documento.png'
import lezione from '../../assets/carrellielevatori/lezione.png'
import obiettivi from '../../assets/carrellielevatori/obiettivi.png'
import omino from '../../assets/carrellielevatori/omino.png'
import persone from '../../assets/carrellielevatori/persone.png'
import posizione from '../../assets/carrellielevatori/posizione.png'
import scarpa from '../../assets/carrellielevatori/scarpa.png'
import spuntato from '../../assets/carrellielevatori/spuntato.png'
import striscione from '../../assets/servsanitari/Banner corso primo soccorso.jpg'

const blsd = () => {
    return (
        <>
        <div >
          <section className='section bg-white'>
            <div ><br></br><br></br>
                <h1 id='tit2'>BLS-D</h1>
                <div style={{display:'flex',width: '100%', height: '0%',alignItems:'center', justifyContent:'center', marginBottom:'3%'}}>
                    <img style={{color: '#035394', textAlign: 'center', width:'80%'}} src={striscione}></img>
                </div>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <h4 style={{color: '#035394', textAlign: 'center', width:'60%'}}>FORMAZIONE BASIC LIFE SUPPORT AND DEFIBRILLATION (BLS-D) </h4>
                </div>
                <br></br>
                <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                    <div style={{width: '80%', height: '80%', textAlign: 'center'}}>
                        <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il corso Basic Life Support and Defibrillation (BLS-D), ovvero supporto di base delle funzioni vitali e defibrillazione,
                        è un programma formativo progettato per fornire a operatori non sanitari le competenze necessarie per intervenire in situazioni di emergenza,
                        come l'arresto cardiaco e/o respiratorio. Con il termine BLS-D si fa riferimento ad una sequenza di azioni ben definite finalizzate
                        a incrementare la possibilità di sopravvivenza dell'individuo colpito da morte cardiaca improvvisa o da disturbi che portano
                        ad arresto cardiorespiratorio. In assenza di un intervento immediato, le chance di sopravvivenza sono estremamente ridotte;
                        tuttavia, l'applicazione tempestiva di manovre di primo soccorso può fare una differenza cruciale,
                        incrementando notevolmente le possibilità di salvare una vita e prevenire danni cerebrali. 
                        Tra le manovre previste dal protocollo BLS-D è presente l'utilizzo del defibrillatore, ovvero un dispositivo semiautomatico che,
                        quando necessario provvede ad erogare una scarica elettrica al cuore del paziente. 
                        </p>
                        {/* <p style={{fontSize:'80%', textAlign: 'justify'}}>
                        Il preposto per adempiere efficacemente ai propri obblighi, elencati dall'art. 19 del D.lgs. 81/08, deve ricevere un'adeguata
                         e specifica formazione e relativo aggiornamento periodico così come previsto dall'art. 37 del D.lgs. 81/08.
                        </p> */}
                    </div>
                </div>
                <br></br><br></br>
                {/* <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={persone} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DESTINATARI DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso è rivolto ai preposti così come definiti dall'art. 2 comma 1 lettera e) del D.Lgs. 81/08, ovvero: persona che,
                                in ragione delle competenze professionali e nei limiti di poteri gerarchici e funzionali adeguati alla natura
                                dell’incarico conferitogli, sovrintende alla attività lavorativa e garantisce l’attuazione delle direttive ricevute,
                                controllandone la corretta esecuzione da parte dei lavoratori ed esercitando un funzionale potere di iniziativa. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br> */}
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={documento} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>PROGRAMMA DEL CORSO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso ha una durata complessiva di 5 ore e si articola in una parte teorica di un'ora e una parte pratica di quattro ore.
                                Durante il corso verranno trattati i seguenti argomenti.
                                <li style={{textAlign:'left', fontSize:'100%'}}>Riconoscimento precoce e trattamento dell'arresto cardiaco.</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Attivazione rapida del Sistema di Emergenza Avanzato (118-112).</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Esecuzione del BLS-D:<ul style={{ fontSize:'100%'}}>a. compressioni toraciche;<br></br>b.	defibrillazione semiautomatica.</ul></li>   
                                <li style={{textAlign:'left', fontSize:'100%', marginTop:'-1%'}}>Riconoscimento e trattamento dell'ostruzione delle vie aeree da corpo estraneo.</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Esecuzione della posizione laterale di sicurezza. </li>  
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={obiettivi} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>OBIETTIVI</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Il corso Basic Life Support and Defibrillation (BLS-D) si pone l’obiettivo di diffondere ampiamente le tecniche di rianimazione cardiopolmonare,
                                coinvolgendo anche il personale non sanitario. Lo scopo è quello di formare il maggior numero possibile di persone
                                capaci di intervenire efficacemente in situazioni di emergenza, fornendo un supporto vitale
                                in attesa dell'arrivo dei soccorritori professionisti.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={scarpa} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>DISPOSITIVI DI PROTEZIONE INDIVIDUALE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                Per svolgere la prova pratica necessaria per conseguire l’attestato di abilitazione è necessario essere muniti di
                                scarpe protettive e indossarle.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
                                
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={attestato} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>CERTIFICAZIONE E AGGIORNAMENTO</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                La partecipazione al corso e il superamento della valutazione finale consentono di ottenere una certificazione.
                                È raccomandata la frequenza a un corso di retraining ogni due anni, soprattutto per rimanere aggiornati sulle eventuali modifiche
                                delle linee guida internazionali. Il corso di aggiornamento ha una durata di 5 ore.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={lezione} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>METODOLOGIA DIDATTICA</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                La metodologia didattica del corso sarà basata su un approccio attivo, che pone l’allievo al centro del processo di apprendimento.
                                Per garantire un efficace insegnamento, saranno adottate le seguenti pratiche. 
                                <li style={{textAlign:'left', fontSize:'100%'}}>Equilibrio tra lezioni frontali e attività interattive. Le lezioni frontali forniranno le necessarie basi teoriche, arricchite da materiali multimediali per migliorare l’esperienza di apprendimento.</li>   
                                <li style={{textAlign:'left', fontSize:'100%'}}>Dimostrazioni e sessioni pratiche in piccoli gruppi sulle tecniche del protocollo BLS-D, utilizzando manichini per simulazioni reali.</li> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>           
                <br></br>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'flex-start', width: '94%', height: '80%'}}>    
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign:'left', width: '100%', height: '80%', overflow: 'auto'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img src={spuntato} style={{width:'7%', height:'14%', marginRight: '0%', marginLeft:'10%'}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign:'left', width: '80%', height: '80%', overflow: 'auto'}}>
                                <h6 style={{color: '#035394', fontSize:'90%'}}>OBBLIGO DEL DEFIBRILLATORE</h6>
                                <p style={{width: '100%', wordWrap: 'break-word', fontSize:'80%'}}>
                                <ol style={{textAlign:'left', fontSize:'100%'}}>
                                    <li style={{textAlign:'left', fontSize:'100%'}}>Associazioni e società sportive dilettantistiche.<br></br> 
                                        Con Decreto 26 giugno 2017 (pubblicato in GURI n. 149 del 28.06.2017) è stato confermato l’obbligo, a partire dal 1° luglio 2017,
                                        di dotazione e impiego del defibrillatore semiautomatico da parte delle associazioni e società sportive dilettantistiche.
                                        I soggetti menzionati assolvono all’obbligo: 1) utilizzando un impianto sportivo dotato di defibrillatore semiautomatico
                                        o a tecnologia più avanzata; 2) assicurando la presenza di una persona formata all’uso del dispositivo durante le gare e, in generale,
                                        durante lo svolgimento di attività sportive con modalità competitive. Associazioni e società sportive hanno l’obbligo di accertare,
                                        prima dell’inizio delle gare, la presenza del DAE nell’impianto, la regolare manutenzione e funzionamento dello stesso, nel rispetto
                                        delle linee guida di cui all’allegato E del Decreto 24 aprile 2013. La mancanza del DAE determina l’impossibilità di svolgere
                                        l’attività sportiva. L’obbligo non riguarda le gare, organizzate da società o associazioni sportive dilettantistiche,
                                        con ridotto impegno cardiocircolatorio, elencate nell'allegato A del Decreto 26 giugno 2017 o svolte al di fuori degli impianti
                                        sportivi.
                                    </li>
                                    <br></br>
                                    <li style={{textAlign:'left', fontSize:'100%'}}>Luoghi pubblici. 
                                        La legge n° 116 del 04/08/2021, pubblicata nella Gazzetta Ufficiale del 13/08/2021, favorisce con un programma pluriennale la progressiva
                                        diffusione e utilizzazione dei DAE presso i luoghi pubblici.
                                        I luoghi pubblici dove potrà essere installato il DAE saranno:
                                        <ul style={{textAlign:'left', fontSize:'100%'}}>•	sedi delle pubbliche amministrazioni* in cui siano impiegati almeno quindici dipendenti e che abbiano servizi aperti al pubblico; </ul>
                                        <ul style={{textAlign:'left', fontSize:'100%'}}>•	negli aeroporti; </ul>
                                        <ul style={{textAlign:'left', fontSize:'100%'}}>•	nelle stazioni ferroviarie; </ul>
                                        <ul style={{textAlign:'left', fontSize:'100%'}}>•	nei porti; </ul>
                                        <ul style={{textAlign:'left', fontSize:'100%'}}>•	a bordo dei mezzi di trasporto aerei, ferroviari, marittimi e della navigazione interna che effettuano tratte con percorrenza continuata, senza possibilità di fermate intermedie, della durata di almeno due ore.  </ul>
                                        
                                    </li>
                                </ol> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>           
            </div>
            <p style={{width: '80%', wordWrap: 'break-word', fontSize:'50%'}}>*Per amministrazioni pubbliche si intendono tutte le amministrazioni dello Stato, ivi compresi gli istituti e scuole di ogni ordine e grado e le istituzioni educative, le aziende ed amministrazioni dello Stato ad ordinamento autonomo, le Regioni, le Province, i Comuni, le Comunità montane, e loro consorzi e associazioni, le istituzioni universitarie, gli Istituti autonomi case popolari, le Camere di commercio, industria, artigianato e agricoltura e loro associazioni, tutti gli enti pubblici non economici nazionali, regionali e locali, le amministrazioni, le aziende e gli enti del Servizio sanitario nazionale, l’Agenzia per la rappresentanza negoziale delle pubbliche amministrazioni (ARAN) e le Agenzie di cui al decreto legislativo 30 luglio 1999, n. 300.</p>
          </section>
        </div>
        </>
    )
}

export default blsd;