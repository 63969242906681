/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { CSSTransition } from 'react-transition-group';
import emailjs from '@emailjs/browser';
import React, { useState, useRef } from 'react'
import '../styles/Cards.css'
import '../styles/PopUp.css'
import rspp from '../assets/rspp.jpg'
import controlli from '../assets/controlli.jpg'
import duvri from '../assets/duvri.jpg'
import tempo from '../assets/tempo.jpg'
import dvr from '../assets/dvr.jpg'
import { toast } from 'react-toastify';

import '../styles/HomeScreen.css'
  const ssl = () => {
    const [isOpen, setIsOpen] = useState(false);
    const form = useRef();

    const [modal] = useState(false);
    const [d, setD] = useState(false)
    if(modal) {
      document.body.classList.add('active-modal')
    } else {
      document.body.classList.remove('active-modal')
    }
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_foa7t03', 'template_1v45r88', form.current, 'TS9WbpKrWNq2gt0U_')
      .then((result) => {
          console.log(result.text);
          toast.success(' Richiesta Effettuata!', {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }, (error) => {
          console.log(error.text);
          toast.error('🦄 Wow so easy!', {
            position: "top-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      });
      e.target.reset();
    };
    
  return (
    <>
    <div >
      <section className='section bg-white h-100'>
        <div className='container1'><br></br><br></br><br></br><br></br>
          <h1 id='tit'>Sicurezza sul Lavoro</h1> 
          <div>
            <div id='descrserv'>
              <p id='po'>La vostra sicurezza è al primo posto.
                        Valutiamo ogni rischio, diamo forma ai vostri documenti,
                        supervisioniamo le vostre attività e ne controlliamo le
                        evoluzioni, anche strumentali.</p>
            </div>
          </div>
          <div id='descrserv2'>

      <button id='bssl1' onClick={() => setIsOpen(!isOpen)}>
         Richiedi un Audit Gratuito
      </button>


      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="popup"
        unmountOnExit
        >
        <div className="popup-content ">
          <p style={{ color: "white", fontSize:'1.5rem', fontWeight:'bold'}}>Abbiamo bisogno di alcune Informazioni..</p><br></br>
          <form ref={form} onSubmit={sendEmail}>
              <div >
                <div style={{ width: "40%" }}>
                  <label htmlFor="nominativo" style={{ color: "white" }}>Il tuo Nominativo:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="nominativo" name="nominativo" required />
                </div>
                <br />
                <br />
                <div style={{ width: "90%" }}>
                  <label htmlFor="nominativo" style={{ color: "white" }}>Il Nome della tua Impresa(facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="nImpresa" name="nImpresa" />
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "40%" }}>
                  <label htmlFor="email" style={{ color: "white" }}>La tua Email:</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="email" id="email" name="user_email" required />
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="provincia" style={{ color: "white" }}>In quale Provincia si trova la tua Impresa:</label>
                </div>
                <div style={{ width: "100%" }}>
                <select id="province" style={{ width: "100%" }} name='user_province' required>
                  <option value="”Agrigento”">Agrigento</option>
                  <option value="”Alessandria”">Alessandria</option>
                  <option value="”Ancona”">Ancona</option>
                  <option value="”Aosta”">Aosta</option>
                  <option value="”Arezzo”">Arezzo</option>
                  <option value="”Ascoli piceno”">Ascoli piceno</option>
                  <option value="”Asti”">Asti</option>
                  <option value="”Avellino”">Avellino</option>
                  <option value="”Bari”">Bari</option>
                  <option value="”Barletta-Andria-Trani”">Barletta-Andria-Trani</option>
                  <option value="”Belluno”">Belluno</option>
                  <option value="”Benevento”">Benevento</option>
                  <option value="”Bergamo”">Bergamo</option>
                  <option value="”Biella”">Biella</option>
                  <option value="”Bologna”">Bologna</option>
                  <option value="”Bolzano”">Bolzano</option>
                  <option value="”Brescia”">Brescia</option>
                  <option value="”Brindisi”">Brindisi</option>
                  <option value="”Cagliari”">Cagliari</option>
                  <option value="”Caltanissetta”">Caltanissetta</option>
                  <option value="”Campobasso”">Campobasso</option>
                  <option value="”Carbonia-Iglesias”">Carbonia-Iglesias</option>
                  <option value="”Caserta”">Caserta</option>
                  <option value="”Catania”">Catania</option>
                  <option value="”Catanzaro”">Catanzaro</option>
                  <option value="”Chieti”">Chieti</option>
                  <option value="”Como”">Como</option>
                  <option value="”Cosenza”">Cosenza</option>
                  <option value="”Cremona”">Cremona</option>
                  <option value="”Crotone”">Crotone</option>
                  <option value="”Cuneo”">Cuneo</option>
                  <option value="”Enna”">Enna</option>
                  <option value="”Fermo”">Fermo</option>
                  <option value="”Ferrara”">Ferrara</option>
                  <option value="”Firenze”">Firenze</option>
                  <option value="”Foggia”">Foggia</option>
                  <option value="”Forli’-Cesena”">Forli’-Cesena</option>
                  <option value="”Frosinone”">Frosinone</option>
                  <option value="”Genova”">Genova</option>
                  <option value="”Gorizia”">Gorizia</option>
                  <option value="”Grosseto”">Grosseto</option>
                  <option value="”Imperia”">Imperia</option>
                  <option value="”Isernia”">Isernia</option>
                  <option value="”la Spezia”">la Spezia</option>
                  <option value="”l’Aquila”">l’Aquila</option>
                  <option value="”Latina”">Latina</option>
                  <option value="”Lecce”">Lecce</option>
                  <option value="”Lecco”">Lecco</option>
                  <option value="”Livorno”">Livorno</option>
                  <option value="”Lodi”">Lodi</option>
                  <option value="”Lucca”">Lucca</option>
                  <option value="”Macerata”">Macerata</option>
                  <option value="”Mantova”">Mantova</option>
                  <option value="”Massa-Carrara”">Massa-Carrara</option>
                  <option value="”Matera”">Matera</option>
                  <option value="”Medio Campidano”"> Medio Campidano</option>
                  <option value="”Messina”">Messina</option>
                  <option value="”Milano”">Milano</option>
                  <option value="”Modena”">Modena</option>
                  <option value="”Monza e della Brianza”">Monza e della Brianza</option>
                  <option value="”Napoli”">Napoli</option>
                  <option value="”Novara”">Novara</option>
                  <option value="”Nuoro”">Nuoro</option>
                  <option value="”Ogliastra”">Ogliastra</option>
                  <option value="”Olbia-Tempio”">Olbia-Tempio</option>
                  <option value="”Oristano”">Oristano</option>
                  <option value="”Padova”">Padova</option>
                  <option value="”Palermo”">Palermo</option>
                  <option value="”Parma”">Parma</option>
                  <option value="”Pavia”">Pavia</option>
                  <option value="”Perugia”">Perugia</option>
                  <option value="”Pesaro e Urbino”">Pesaro e Urbino</option>
                  <option value="”Pescara”">Pescara</option>
                  <option value="”Piacenza”">Piacenza</option>
                  <option value="”Pisa”">Pisa</option>
                  <option value="”Pistoia”">Pistoia</option>
                  <option value="”Pordenone”">Pordenone</option>
                  <option value="”Potenza”">Potenza</option>
                  <option value="”Prato”">Prato</option>
                  <option value="”Ragusa”">Ragusa</option>
                  <option value="”Ravenna”">Ravenna</option>
                  <option value="”Reggio di Calabria”">Reggio di Calabria</option>
                  <option value="”Reggio nell’Emilia”">Reggio nell’Emilia</option>
                  <option value="”Rieti”">Rieti</option>
                  <option value="”Rimini”">Rimini</option>
                  <option value="”Roma”">Roma</option>
                  <option value="”Rovigo”">Rovigo</option>
                  <option value="”Salerno”">Salerno</option>
                  <option value="”Salerno”">Salerno</option>
                  <option value="”Savona”">Savona</option>
                  <option value="”Siena”">Siena</option>
                  <option value="”Siracusa”">Siracusa</option>
                  <option value="”Sondrio”">Sondrio</option>
                  <option value="”Taranto”">Taranto</option>
                  <option value="”Teramo”">Teramo</option>
                  <option value="”Terni”">Terni</option>
                  <option value="”Torino”">Torino</option>
                  <option value="”Trapani”">Trapani</option>
                  <option value="”Trento”">Trento</option>
                  <option value="”Treviso”">Treviso</option>
                  <option value="”Trieste”">Trieste</option>
                  <option value="”Udine”">Udine</option>
                  <option value="”Varese”">Varese</option>
                  <option value="”Venezia”">Venezia</option>
                  <option value="”Verbano-Cusio-Ossola”">Verbano-Cusio-Ossola</option>
                  <option value="”Vercelli”">Vercelli</option>
                  <option value="”Verona”">Verona</option>
                  <option value="”Vibo Valentia”">Vibo Valentia</option>
                  <option value="”Vicenza”">Vicenza</option>
                  <option value="”Viterbo”">Viterbo</option>
              </select>
                  {/* <input type="text" id="provincia" name="provincia" /> */}
                </div>
              </div>
              <br />
              <br />
              <div >
                <div style={{ width: "90%" }}>
                  <label htmlFor="settore" style={{ color: "white" }}>In che Settore opera la tua Impresa(facoltativo):</label>
                </div>
                <div style={{ width: "100%" }}>
                  <input style={{ width: "100%" }} type="text" id="settore" name="settore" />
                </div>
              </div>
              <br />
              <div style={{ fontWeight:'bold', color:'white', fontSize:'13px' }}>
              Tratteremo i tuoi dati nel pieno rispetto dei principi dettati dal Regolamento Europeo in materia di protezione dei dati personali (GDPR) .
               <br/>Cliccando su "Invia richiesta" accetti la nostra Informativa<a href='#'style={{ fontWeight:'bold', color:'white', fontSize:'13px' }}>(consultabile qui).</a>
              </div>
              <br />
              <input type="submit" value="Invia Richiesta" id='bssl2'/>
            </form>
        </div> 
      </CSSTransition>
        

          </div>
          <div id='descrserv3'>
            ti risponderemo in 24 ore
          </div>
          <div className='cards'>
            {
              <div className='card'>
                <img src={rspp} id='cardimg'>{rspp.title}</img><br></br><br></br>
                <h3 id='tecard'>INCARICO DI RESPONSABILE DEL SERVIZIO
                                DI PREVENZIONE E PROTEZIONE</h3><br></br><br></br>
                {/* {
                  show?<><p id='pcol'></p><br></br><br></br><br></br></>:null
                } */}
            <div id='alb'>
              
              {/* <div>
                <button id='bssl' onClick={()=>setShow(false)}>Nascondi</button>
              </div> */}
            </div>
              </div>
            }
            {
              
              <div className='card'>
                <img src={controlli} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>ISTITUZIONE E SUPERVISIONE DEL
                                REGISTRO DEI CONTROLLI</h3><br></br><br></br>
                {/* {
                  d?<><p id='pcol'>erttyu</p><br></br><br></br><br></br></>:null
                } */}
                {/* <div id='alb'>             
                  <button id='bssl'  onClick={()=>setD(true)}>Mostra dettagli</button><br></br><br></br>
                  <button id='bssl' onClick={()=>setD(false)}>Nascondi</button>
                </div> */}
              </div>
            }
            {
              
              <div className='card'>
                <img src={duvri} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>PREDISPOSIZIONE DEL DOCUMENTO UNICO DI VALUTAZIONE DEI RISCHI INTERFERENZIALI (DUVRI)</h3><br></br><br></br>
                {/* {
                  de?<><p id='pcol'>erttyu</p><br></br><br></br><br></br></>:null
                } */}
                {/* <div id='alb'>             
                  <button id='bssl'  onClick={()=>setDe(true)}>Mostra dettagli</button><br></br><br></br>
                  <button id='bssl' onClick={()=>setDe(false)}>Nascondi</button>
                </div> */}
              </div>
            }
            {
              
              <div className='card'>
                <img src={tempo} id='cardimg'></img><br></br><br></br>
                <h3 id='tecard'>SERVIZIO DI SCADENZIARIO</h3><br></br><br></br>
                {/* {
                  dr?<><p id='pcol'>erttyu</p><br></br><br></br><br></br></>:null
                } */}
                {/* <div id='alb'>             
                  <button id='bssl'  onClick={()=>setDr(true)}>Mostra dettagli</button><br></br><br></br>
                  <button id='bssl' onClick={()=>setDr(false)}>Nascondi</button>
                </div> */}
              </div>
            }
              </div>
            {
              <div className='cards1'>
                <div className='card'>
                  <img src={dvr} id='cardimg'></img><br></br><br></br>
                  <h3 id='tecard'>REDAZIONE DEL DOCUMENTO DI VALUTAZIONE DEI RISCHI</h3><br></br><br></br>
                  {
                    d?<><p id='pcol1'>
                      <ul>
                        <li>
                          LUOGHI DI LAVORO
                        </li>
                        <li>
                          MACCHINE ED ATTREZZATURE;
                        </li>
                        <li>
                          CHIMICO;
                        </li>
                        <li>
                          BIOLOGICO;
                        </li>
                        <li>
                        RUMORE;
                        </li>
                        <li>
                        VIBRAZIONI;
                        </li>
                        <li>
                        RADIAZIONI OTTICHE ARTIFICIALI;
                        </li>
                        <li>
                        CAMPI ELETTROMAGNETICI E ONDE IONIZZANTI;
                        </li>
                        <li>
                        ELETTRICO;
                        </li>
                        <li>
                        ESPLOSIONI (ATEX);
                        </li>
                        <li>
                        INCENDIO;
                        </li>
                        <li>
                        ERGONOMIA
                        </li>
                        <li>
                        (movimentazione manuale dei carichi)
                        </li>
                        <li>
                        (sovraccarico biomeccanico degli arti superiori)
                        </li>
                        <li>
                        (traino spinta)
                        </li>
                        <li>
                        (posture incongrue)
                        </li>
                        <li>
                        AGENTI CANCEROGENI E MUTAGENI;
                        </li>
                        <li>
                        STRESS LAVORO CORRELATO;
                        </li>
                        <li>
                        COMFORT ILLUMINOTECNICO;
                        </li>
                        <li>
                        COMFORT MICROCLIMATICO.
                        </li>
                      </ul>
                      </p><br></br><br></br><br></br></>:null
                  }
                  <div id='alb'>    
                    <button id='bssl' onClick={()=>setD(!d)}>dettagli</button><br></br><br></br>
                  </div>
                </div>
              </div>
            }
        </div>
      </section>
    </div>
    </>
  )
}

export default ssl